import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { ICollumnMapper, ISelectedCompanies } from '@/types';

const initialState = {
  isLoading: false,
  selectedCompanies: [] as ISelectedCompanies[],
  isSelectedAll: false,
  currency: 'USD',
  columnMapper: [] as ICollumnMapper[],
};

export const { reducer, actions } = createSlice({
  name: 'customScreen',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setSelectedCompany: (state, { payload }: PayloadAction<ISelectedCompanies>) => {
      const item = _.find(state.selectedCompanies, { id: payload.id });

      const result = item
        ? _.reject(state.selectedCompanies, { id: payload.id })
        : _.concat(state.selectedCompanies, payload);

      state.selectedCompanies = result;
    },
    setSelectedCompanines: (state, { payload }: PayloadAction<ISelectedCompanies[]>) => {
      state.selectedCompanies = payload;
      state.isSelectedAll = !_.isEmpty(payload);
    },
    reset: (state) => {
      state.selectedCompanies = [];
      state.isSelectedAll = false;
    },
    setCurrency: (state, { payload }: PayloadAction<string>) => {
      state.currency = payload;
    },
    setColumnMapper: (state, { payload }: PayloadAction<ICollumnMapper[]>) => {
      state.columnMapper = payload;
    },
  },
});

export type TState = typeof initialState;
