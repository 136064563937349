import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { clearSearchSubject } from '../../Utils/subjects';

export const Main = ({ children }) => {
  const queryClient = useQueryClient();
  const location = useLocation();

  useEffect(() => {
    const subscription = clearSearchSubject.subscribe(() => {
      queryClient.invalidateQueries({
        queryKey: ['filter-tree'],
      });
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ['filter-tree'],
    });
  }, [location]);

  return <main data-testid="appComponent">{children}</main>;
};

Main.propTypes = {
  children: PropTypes.node.isRequired,
};
