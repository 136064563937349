import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';
import { actions as searchActions } from '../slices/search';
import { actions as filterActions } from '../slices/filters';
import { normalizeQueryString, sanitizeQueryString, guessUserFriendlyLuceneError } from '@/Utils/pegjs/astTreeUtils';
import { searchSubject } from '@/Utils/subjects';
import { useSearchHistory } from './use-save-search-query';
import { useShallowSelector } from './use-shallow-selector';
import { Nullish } from '@/types';
import { DEFAULT_ERROR_MESSAGE } from '@/constants';

const mergeQueries = (prevQuery: string, nextQuery: string) =>
  !prevQuery ? nextQuery : `${prevQuery} AND ${nextQuery}`;

export const useSearchCompanies = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { saveQuery } = useSearchHistory();
  const searchQuery = useShallowSelector((state) => state.search.searchQuery);
  const searchTextValue = useShallowSelector((state) => state.search.searchText);
  const searchQueryTree = useShallowSelector((state) => state.search.searchQueryTree);
  const isLuceneState = useShallowSelector((state) => state.search.isLuceneQuery);
  const hasLuceneGrammarError = useShallowSelector((state) => state.search.hasLuceneGrammarError);
  const shouldSaveQuery = useRef<Nullish<{ searchText: string; isUniq?: boolean }>>();

  useEffect(() => {
    if (shouldSaveQuery.current) {
      saveQuery({
        searchText: shouldSaveQuery.current.searchText,
        isLucene: isLuceneState,
        isUniq: shouldSaveQuery.current.isUniq,
      });
      shouldSaveQuery.current = null;
    }
  }, [searchQueryTree]);

  useEffect(() => {
    if (hasLuceneGrammarError) {
      dispatch(searchActions.setHasLuceneGrammarError(false));
    }
  }, [searchTextValue]);

  const elasticSearch = (searchValue: string, concatPrevQuery: boolean) => {
    const searchText = !concatPrevQuery
      ? searchValue
      : !searchValue
      ? searchQuery
      : mergeQueries(searchQuery, searchValue);

    try {
      const normalizedQuery = normalizeQueryString(sanitizeQueryString(searchText));

      // bainIds here is a workaround to make ids reset to [], should be fixed after refactor
      searchSubject.next({
        text: normalizedQuery,
        bainIds: normalizedQuery ? null : [],
      });

      dispatch(searchActions.setSearchQuery(normalizedQuery));

      if (normalizedQuery) {
        shouldSaveQuery.current = { searchText: normalizedQuery };
      }
    } catch (error) {
      dispatch(searchActions.setHasLuceneGrammarError(true));

      const luceneQueryError = guessUserFriendlyLuceneError(searchText);
      const isDefaultMessage = luceneQueryError === DEFAULT_ERROR_MESSAGE;

      enqueueSnackbar(
        <NotificationMessage
          title={isDefaultMessage ? DEFAULT_ERROR_MESSAGE : 'Your query is not valid'}
          description={!isDefaultMessage ? luceneQueryError : undefined}
        />,
        { variant: 'error' },
      );
    }
  };

  const regularSearch = (searchValue: string, isUniq?: boolean) => {
    // bainIds here is a workaround to make ids reset to [], should be fixed after refactor
    searchSubject.next({
      text: searchValue,
      bainIds: searchValue ? null : [],
      isUniq,
    });

    dispatch(searchActions.setSearchText(searchValue));
    dispatch(searchActions.setSearchQuery(searchValue));

    if (searchValue) {
      shouldSaveQuery.current = { searchText: searchValue, isUniq };
    }
  };

  const search = (
    {
      isLucene,
      searchText,
      isUniq,
      filters,
    }: {
      isLucene: boolean;
      searchText: string;
      isUniq?: boolean;
      filters?: any;
    },
    { concatPrevQuery, useStateFilters } = {
      concatPrevQuery: true,
      useStateFilters: true,
    },
  ) => {
    const searchValue = searchText.trim();

    if (!searchValue) {
      dispatch(searchActions.setBainIds([]));
    }

    if (isLuceneState !== isLucene) {
      dispatch(searchActions.setIsLuceneQuery(isLucene));
    }

    if (!useStateFilters) {
      dispatch(filterActions.setFilters(filters ?? {}));
    }

    if (isLucene) {
      elasticSearch(searchValue, concatPrevQuery);
    } else {
      regularSearch(searchValue, isUniq);
    }
  };

  return search;
};
