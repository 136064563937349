import { useLocalStorage } from 'usehooks-ts';
import { LOCAL_STORAGE_KEYS } from '@/constants';
import {
  getLastNSearches,
  getLatestQuery,
  getQueryEntity,
  prepareSearchHistory,
  removeTooOldSearchHistoryQueries,
  shouldSaveNewQuery,
} from '@/Utils/searchQueriesHistoryUtils';

const NUMBER_OF_DISPLAYED_SEARCHES = 5;

export interface HistoryQuery {
  createTimestamp: number;
  searchText: string;
  isLucene: boolean;
  filters?: any;
  isUniq?: boolean;
  companiesCount?: number;
  isExport?: boolean;
}

export type QueryDetails = Omit<HistoryQuery, 'createTimestamp'>;

export const useSearchHistory = () => {
  const [queries, setQueries] = useLocalStorage(LOCAL_STORAGE_KEYS.QUERIES_HISTORY, [] as HistoryQuery[]);

  const saveQuery = (queryDetails: QueryDetails) => {
    setQueries((prevQueries: HistoryQuery[]) => {
      const latestQuery = getLatestQuery(prevQueries);
      const newQuery = getQueryEntity({
        ...queryDetails,
        filters: queryDetails.isExport ? queryDetails.filters : undefined,
      });

      return shouldSaveNewQuery(latestQuery, newQuery) ? [...prevQueries, newQuery] : [...prevQueries];
    });
  };

  const cleanOldHistory = () => {
    setQueries((prevQueries) => {
      const newQueries = removeTooOldSearchHistoryQueries(prevQueries);

      return newQueries.length !== prevQueries.length ? newQueries : prevQueries;
    });
  };

  const getLastNSearchQueries = (n = NUMBER_OF_DISPLAYED_SEARCHES) => {
    return getLastNSearches(queries, n);
  };

  const getGroupedSearchHistory = () => {
    return prepareSearchHistory(queries);
  };

  return {
    cleanOldHistory,
    saveQuery,
    getLastNSearchQueries,
    getGroupedSearchHistory,
    queries,
  };
};
