/**************************************************************************
*
*     Copyright Bain & Company. 2020, 2021
*                                       
**************************************************************************/
import React from "react";
import { Switch, Route } from "react-router-dom";
import GuardedRoute from "./routes";
import Login from "../Components/login";
import { CompanyResearchDecorator } from "../Components/CompanyResearch/CompanyResearch.decorator";
import sectorScreening from "../Components/sectorScreening";
import HomePage from "../Components/homePage";
import NotFound from "../Components/NotFound";
import StandardScreener from "../Components/StandardScreener/StandardScreener";
import SavedScreeners from "../Components/savedScreeners";
import SwaggerUI from "../Components/Swagger/SwaggerUI"

export default function Routes() {
  return (
    <Switch>
      <Route path="/" component={Login} exact />
      <GuardedRoute
        path="/Screener/:selectedTab"
        component={StandardScreener}
      />
      <GuardedRoute path="/customScreen" component={CompanyResearchDecorator} />
      <GuardedRoute path="/quickScreen" component={sectorScreening} />
      <GuardedRoute path="/savedScreeners" component={SavedScreeners} />
      <GuardedRoute path="/homePage" component={HomePage} />
      <GuardedRoute path="/swagger" component={SwaggerUI} />
      <Route component={NotFound} />
    </Switch>
  );
}
