import API from '@aws-amplify/api-rest';

export const downloadCompanyOverview = async (target_id, competitor_ids, currency, email, caseCodeFormData) => {
  const body = {
    target_id,
    competitor_ids,
    currency,
    email,
    ...(caseCodeFormData?.caseCode
      ? { case_code: caseCodeFormData.caseCode }
      : {
          project_name: caseCodeFormData?.projectName,
          case_type: caseCodeFormData?.caseType,
          project_type: caseCodeFormData?.projectType,
        }),
  };

  return await API.post('CDPAPI', '/company_overview_slides', {
    body,
  });
};
