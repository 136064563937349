import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Skeleton from '@mui/material/Skeleton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { Tree } from 'antd';
import classnames from 'classnames';
import _ from 'lodash';

import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { actions } from '@/slices/filters';
import { prepareFilterTree } from '@/Utils/filters';

const TreeCheckbox = ({ checked, halfChecked }) => {
  if (checked) return <CheckBoxIcon className="mr-2 fill-[#0288d1]" />;

  if (halfChecked) return <IndeterminateCheckBoxIcon className="mr-2 fill-[#0288d1]" />;

  return <CheckBoxOutlineBlankIcon className="mr-2 fill-[#DDD]" />;
};

export const FilterTree = ({ itemId, data, isFetching }) => {
  const dispatch = useDispatch();
  const nodes = useMemo(() => prepareFilterTree({ data: data ?? [], Icon: TreeCheckbox }), [data]);
  const checkedKeys = useShallowSelector(
    (state) => state.filters.treeFilters[itemId] ?? [],
  );

  const handleChange = (checked) => {
    dispatch(
      actions.updateFilterWith({
        payload: { id: itemId, data: checked },
        action: actions.setFilterTree,
      }),
    );
  };

  return isFetching ? (
    <Skeleton variant="rounded" className="bg-[#0000000f] mr-4 my-4" />
  ) : (
    <Tree
      checkable
      showIcon
      treeData={nodes}
      selectable={false}
      checkedKeys={checkedKeys}
      onCheck={handleChange}
      defaultExpandedKeys={[nodes[0].key]}
      switcherIcon={(props) => (
        <KeyboardArrowDownIcon
          className={classnames('fill-[#979797] text-xl mt-0.5 transition', {
            'rotate-180': props.expanded,
          })}
        />
      )}
      className="mt-2"
    />
  );
};
