import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '@aws-amplify/api-rest';

import { TRootState } from '@/store';
import { actions } from '../index';

export const fetchProfile = createAsyncThunk('companyProfile', async (bainId, { dispatch, getState }) => {
  try {
    const {
      customScreen: { currency },
    } = getState() as TRootState;

    dispatch(actions.setIsLoading(true));

    const response = await Promise.all([
      API.post('CDPAPI', `/company_profile?currency=${currency}`, {
        body: { bainIds: bainId },
      }),
      API.get('CDPAPI', `/company_profile/news/${bainId}?currency=${currency}`, {}),
      API.get('CDPAPI', `/company_profile/multiyear/${bainId}?currency=${currency}`, {}),
      API.get('CDPAPI', `/company_profile/subsidiaries/${bainId}?currency=${currency}`, {}),
      API.get('CDPAPI', `/company_profile/ma/${bainId}?currency=${currency}`, {}),
    ]);

    dispatch(actions.setIsLoading(false));

    dispatch(
      actions.setCompanyData({
        profile: response[0].data,
        news: response[1].data,
        multiyear: response[2],
        subsidiaries: response[3].data,
        mergesAndAcquisitions: response[4].data,
      }),
    );
  } catch (error) {
    alert(error);
  }
});
