import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import API from '@aws-amplify/api-rest';
import _ from 'lodash';

import { actions } from '@/slices/filters';
import { getFilterIdsByType } from '@/Utils/filters';
import { FILTER_TYPES } from '@/constants';
import { FiltersAvailable } from '@/types';
import { FilterTree } from '@/types/state/filter-tree';
import { TAppDispatch } from '@/store';
import { useShallowSelector } from '../use-shallow-selector';

const switchedCurrency = (currency: string) => (currency === 'EUR' ? 'USD' : 'EUR');

export const useQueryFilterTree = (ids: string[], enabled: boolean) => {
  const dispatch = useDispatch<TAppDispatch>();
  const queryClient = useQueryClient();
  const currency = useShallowSelector((state) => state.customScreen.currency);
  const bain_id = useShallowSelector((state) => state.search.bainIds.data);

  return useQuery(
    ['filter-tree', ids, bain_id, currency],
    () =>
      API.post('CDPAPI', `/filters_tree?currency=${currency}`, {
        body: {
          include_filters: ids,
          ...(!_.isEmpty(bain_id) && { bain_id: bain_id.map(Number) }),
        },
      }),
    {
      enabled,
      cacheTime: Infinity,
      staleTime: Infinity,
      onSuccess: (data) => {
        const availableFilters = queryClient.getQueryData<FiltersAvailable>(['/filters/available', currency]);
        const cashedData = queryClient.getQueryData<FilterTree>([
          'filter-tree',
          ids,
          bain_id,
          switchedCurrency(currency),
        ]);

        if (!cashedData) {
          const numericalFilterIds = getFilterIdsByType(availableFilters?.data, data, FILTER_TYPES.NUMERICAL);

          dispatch(
            actions.updateFilterWith({
              action: actions.setIdsToNullList,
              payload: numericalFilterIds,
            }),
          );
        }
      },
    },
  );
};
