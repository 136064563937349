import { ActionCreatorWithPayload, createAsyncThunk } from '@reduxjs/toolkit';

import { actions as searchActions } from '@/slices/search';

type TThunkPayload = { payload: unknown, action: ActionCreatorWithPayload<unknown, string> }

export const updateFilterWith = createAsyncThunk(
  'filters/updateFilterWith',
  ({ payload, action }: TThunkPayload, { dispatch }) => {
    dispatch(action(payload));
    dispatch(searchActions.setHasChange(true));
  },
);
