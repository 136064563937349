import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import React, { useState, forwardRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { MODALS } from '../../../constants';
import { useModal } from '../../../hooks/use-modal';
import { useSearchHistory } from '../../../hooks/use-save-search-query';
import { IconHistory } from '../../Icons/IconHistory';

const MENU_DEFAULT_WIDTH = 550;

const HistoryDropdown = forwardRef(({ onQuerySelect, onDropdownToggle }, ref) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { getLastNSearchQueries, cleanOldHistory } = useSearchHistory();
  const [queriesToDisplay, setQueriesToDisplay] = useState([]);
  const [menuWidth, setMenuWidth] = useState(MENU_DEFAULT_WIDTH);
  const { handleOpen: handleModalOpen } = useModal(MODALS.SEARCH_COMPANIES_HISTORY);
  const isOpened = !!anchorEl;

  useEffect(() => {
    cleanOldHistory();
  }, []);

  const openDropdown = () => {
    setAnchorEl(ref?.current);
    setQueriesToDisplay(() => getLastNSearchQueries());
    setMenuWidth(ref?.current.offsetWidth ?? MENU_DEFAULT_WIDTH);
    onDropdownToggle?.(true);
  };
  const handleDropdownClose = (query) => {
    setAnchorEl(null);

    if (query && onQuerySelect) {
      onQuerySelect(query);
    }
    onDropdownToggle?.(false);
  };

  const viewFullHistory = () => {
    handleDropdownClose();
    handleModalOpen();
  };

  return (
    <>
      <Button
        aria-controls={isOpened ? 'history-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpened ? 'true' : undefined}
        disableElevation
        onClick={openDropdown}
        classes={{ root: 'min-w-[30px] p-1' }}
      >
        <IconHistory />
        <KeyboardArrowDownIcon
          className={classnames('fill-cgray-50 text-xl transition ml-1', { 'rotate-180': isOpened })}
        />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={isOpened}
        onClose={() => handleDropdownClose()}
        classes={{
          paper: 'rounded mt-1 border border-[#C3C4C4] shadow-[0px_4px_4px_rgba(0,0,0,0.25)]',
        }}
      >
        {queriesToDisplay.map((query) => (
          <MenuItem
            key={query.createTimestamp}
            onClick={() => handleDropdownClose(query)}
            classes={{ root: 'h-[32px] min-h-[32px]' }}
            sx={{ width: menuWidth }}
          >
            <Box
              className="mr-3"
              component="span"
            >
              <IconHistory color="#999999" />
            </Box>
            <Typography
              variant="inherit"
              noWrap
            >
              {query.isLucene ? query.searchText : `"${query.searchText}"`}
            </Typography>
          </MenuItem>
        ))}
        {queriesToDisplay.length === 0 && (
          <Typography
            className="pl-4 mb-2 mt-2 text-sm"
            sx={{ width: menuWidth }}
          >
            No history
          </Typography>
        )}
        <Link
          component="button"
          onClick={viewFullHistory}
          className="ml-4 mb-4 mt-2 text-sm"
        >
          View full history
        </Link>
      </Menu>
    </>
  );
});

HistoryDropdown.displayName = 'HistoryDropdown';

export default HistoryDropdown;
