import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import _ from 'lodash';

import { HIDE_FILTERS_COUNT } from '../../../../../../constants';
import { useShallowSelector } from '../../../../../../hooks/use-shallow-selector';
import { actions } from '../../../../../../slices/filters';
import { getCategoricalFilterValue } from '../../../../../../Utils/filters';


function getCheckboxLabel(value) {
  switch (value) {
    case 'Y':
      return 'Yes';
    case 'N':
      return 'No';
    case null:
      return 'Null';
    case 'UNOWNED':
      return 'Unowned';
    case 'UNKNOWN':
      return 'Unknown';

    default:
      return value;
  }
}

const prepareFilterValue = ({ checked, filterData, value }) =>
  checked ? [...filterData, value] : _.without(filterData, value);

export const FilterCheckboxList = ({ itemId, values }) => {
  const dispatch = useDispatch();
  const filterData = useShallowSelector(
    (state) => state.filters.otherFilters[itemId] ?? []
  );

  const handleChange = (value) => (event, checked) => {
    dispatch(
      actions.updateFilterWith({
        payload: {
          id: itemId,
          data: prepareFilterValue({
            checked,
            filterData,
            value: getCategoricalFilterValue(value),
          }),
        },
        action: actions.setFilter,
      })
    );
  };

  return (
    <div>
      {values.map(({ value, active, total }) => {
        const filterValue = getCategoricalFilterValue(value);
        const isChecked = filterData.includes(filterValue);

        return (
          <FormControlLabel
            key={value}
            control={
              <Checkbox
                color="info"
                sx={{
                  color: '#DDD',
                }}
                checked={isChecked}
                disableRipple
                disableFocusRipple
              />
            }
            onChange={handleChange(value)}
            label={
              HIDE_FILTERS_COUNT
                ? getCheckboxLabel(value)
                : `${getCheckboxLabel(value)} (${total.toLocaleString('en')})`
            }
            classes={{
              root: 'flex',
              label: 'text-[#484848]',
            }}
            value={active}
          />
        );
      })}
    </div>
  );
};

FilterCheckboxList.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, null]).isRequired,
      active: PropTypes.oneOf([0, 1]).isRequired,
      total: PropTypes.number.isRequired,
    })
  ),
};
