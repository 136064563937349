import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { useSearchHistory } from '../../../hooks/use-save-search-query';
import { useSearchCompanies } from '../../../hooks/use-search-companies';
import { useModal } from '../../../hooks/use-modal';
import { MODALS } from '../../../constants';
import { QueryRecord } from './QueryRecord';

export const SearchCompaniesHistoryDialog = () => {
  const searchCompanies = useSearchCompanies();
  const { isOpen, handleClose } = useModal(MODALS.SEARCH_COMPANIES_HISTORY);
  const { queries, getGroupedSearchHistory } = useSearchHistory();
  const [groupedQueries, setGroupedQueries] = useState([]);

  useEffect(() => {
    setGroupedQueries(getGroupedSearchHistory());
  }, [queries]);

  const handleSearch = (query) => {
    searchCompanies(query, {});
    handleClose();
  };

  return (
    <Dialog open={isOpen}
            onClose={handleClose}
            classes={{ paper: 'w-9/12 rounded-lg p-2' }}>
      <DialogTitle className="text-base font-bold pb-2 text-[#484848]">History</DialogTitle>
      <CloseIcon fontSize="small"
                 className="absolute right-4 top-6 cursor-pointer fill-[#484848]"
                 onClick={handleClose} />
      <DialogContent className="text-sm pt-2">
        {
          groupedQueries.map(({ label, queries }) =>
            <Box key={label}>
              <Typography className="flex-none w-16 mb-3 text-[10px] text-cgray-50 uppercase font-medium">
                {label}
              </Typography>
              {
                queries.map(query =>
                  <Box key={query.createTimestamp} className="mb-3">
                    <QueryRecord query={query} onSearch={handleSearch} />
                  </Box>,
                )
              }
            </Box>,
          )
        }
        {queries.length === 0 && <Typography className="mt-2 text-sm">No history</Typography>}
      </DialogContent>
    </Dialog>
  );
};
