import Typography from '@mui/material/Typography';

export const NotificationMessage = ({ title, description }) => {
  return (
    <div className="flex flex-col px-2">
      {title ? <Typography variant="body1">{title}</Typography> : null}
      {description ? (
        <Typography
          variant="body1"
          gutterTop
          gutterBottom
          style={{ wordWrap: 'break-word' }}
        >
          {description}
        </Typography>
      ) : null}
    </div>
  );
};
