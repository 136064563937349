import React from 'react';
import classnames from 'classnames';
import Button, { ButtonTypeMap } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { ExtendButtonBase } from '@mui/material/ButtonBase/ButtonBase';

type Props = {
  id: string;
  isLoading: boolean;
  disabled: boolean;
} & ExtendButtonBase<ButtonTypeMap<Record<string, unknown>, 'button'>>;

export const SearchButton = ({ id = 'custom-search-button', isLoading, disabled = false, ...rest }: Props) => (
  <Button
    id={id}
    disabled={disabled}
    className={classnames('px-3 ml-4', {
      'bg-[#ddd]': disabled,
      'bg-[#cc0100] text-white': !disabled,
    })}
    {...rest}
  >
    Search
    {isLoading ? (
      <CircularProgress
        color="info"
        size={18}
        sx={{ ml: 1 }}
      />
    ) : null}
  </Button>
);
