import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { loggingCaseCode } from './thunks/logging-case-code';

export type TFormData = {
  caseCode: string;
  caseType: string;
  projectName: string;
  clientName: string;
  projectType: string;
};

const initialState = {
  isDialogOpen: false,
  formData: {} as TFormData,
};

const slice = createSlice({
  name: 'caseCode',
  initialState,
  reducers: {
    openDialog: (state) => {
      state.isDialogOpen = true;
    },
    closeDialog: (state) => {
      state.isDialogOpen = false;
    },
    setFormData: (state, action: PayloadAction<TFormData>) => {
      state.formData = action.payload;
    },
  },
});

export const { reducer } = slice;
export const actions = {
  ...slice.actions,
  loggingCaseCode,
};

export type TState = typeof initialState;