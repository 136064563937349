import React from 'react';
import Button, { ButtonTypeMap } from '@mui/material/Button';
import { ExtendButtonBase } from '@mui/material/ButtonBase/ButtonBase';
import { Download } from '@mui/icons-material';

type Props = {
  id: string;
} & ExtendButtonBase<ButtonTypeMap<Record<string, unknown>, 'button'>>;

export const ExportButton = ({ id = 'export-button', ...rest }: Props) => (
  <Button
    id={id}
    className="py-2 pl-3 pr-4 bg-[#cc0100] text-white"
    {...rest}
  >
    <Download className="text-[16px] mr-2 mt-0.5" />
    Export
  </Button>
);
