import { useMemo } from 'react';
import { reduce, isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { useShallowSelector } from '../use-shallow-selector';
import { actions } from '../../slices/filters';
import { FILTER_TYPES } from '../../constants';

export const useCategoryAppliedFilters = ({
  availableCategoryFilters,
  categoryId,
}) => {
  const dispatch = useDispatch();
  const allAppliedFilters = useShallowSelector((state) => state.filters);

  const categoryAppliedFilters = useMemo(() => {
    const { treeFilters, otherFilters } = allAppliedFilters;

    const appliedTreeFilters = reduce(
      treeFilters,
      (acc, item) =>
        acc.concat(
          item
            .map(JSON.parse)
            .filter(
              ({ hasChildren, categoryFilterId }) =>
                !hasChildren && categoryFilterId === categoryId
            )
            .map(({ value }) => value)
        ),
      []
    );

    const appliedOtherFilters = reduce(
      otherFilters,
      (acc, filterData, filterId) => {
        if (isEmpty(filterData)) return acc;

        const currentCategoryFilter = availableCategoryFilters.find(
          ({ backendName }) => backendName === filterId
        );

        if (currentCategoryFilter) {
          return acc.concat(currentCategoryFilter.displayName);
        }

        return acc;
      },
      []
    );

    return [...appliedTreeFilters, ...appliedOtherFilters];
  }, [allAppliedFilters]);

  const formattedCategoryAppliedFilters = useMemo(
    () => categoryAppliedFilters.join(' | '),
    [categoryAppliedFilters]
  );

  const handleRemoveCategoryFilters = (event) => {
    event.stopPropagation();

    availableCategoryFilters.forEach(({ __type__, backendName }) => {
      if (__type__ === FILTER_TYPES.TREE) {
        dispatch(
          actions.updateFilterWith({
            payload: backendName,
            action: actions.removeFilterTree,
          })
        );
        return;
      }

      dispatch(
        actions.updateFilterWith({
          payload: backendName,
          action: actions.removeFilter,
        })
      );

      if (__type__ === FILTER_TYPES.NUMERICAL) {
        dispatch(
          actions.updateFilterWith({
            payload: backendName,
            action: actions.addIdToNullList,
          })
        );
      }
    });
  };

  return {
    numberOfCategoryAppliedFilters: categoryAppliedFilters.length,
    categoryAppliedFilters,
    formattedCategoryAppliedFilters,
    handleRemoveCategoryFilters,
  };
};
