import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import React, { useEffect, useMemo, useState } from 'react';
import Typography from '@mui/material/Typography';
import { debounce, groupBy } from 'lodash';
import styled from 'styled-components';
import { DEFAULT_VISIBLE_COLUMNS, SECTIONS_ORDER } from '../../../constants';
import { useShallowSelector } from '../../../hooks/use-shallow-selector';
import { groupAndSortList } from '../../../Utils';

const EXTENDED_DEFAULT_COLUMNS = ['__check__', ...DEFAULT_VISIBLE_COLUMNS];

const StyledTextField = styled(TextField)(() => ({
  '& .MuiFilledInput-root:hover:before': {
    borderBottomColor: 'black',
  },
}));

const ColumnsVisibilityPanel = () => {
  const { columnMapper } = useShallowSelector(state => state.customScreen);
  const [searchValue, setSearchValue] = useState('');
  const [groupedColumns, setGroupedColumns] = useState([]);
  const [filteredColumns, setFilteredColumns] = useState([]);
  const apiRef = useGridApiContext();
  const columns = apiRef.current.getAllColumns();

  const groupedColumnMapper = useMemo(() => groupBy(columnMapper, 'Backend Name'), [columnMapper]);

  const getSectionByField = (field) =>
    EXTENDED_DEFAULT_COLUMNS.includes(field)
      ? 'Default'
      : groupedColumnMapper[field]?.[0]?.['Display Header'] || 'Others';

  useEffect(() => {
    setGroupedColumns(() => {
      const columnsWithSections = columns.map(({ field, headerName, hide }) => ({
        section: getSectionByField(field),
        headerName,
        field,
        hide,
      }));

      return groupAndSortList(columnsWithSections, SECTIONS_ORDER, 'section');
    });

  }, [columnMapper, columns]);

  useEffect(() =>
      setFilteredColumns(() => searchValue ? filterColumns(groupedColumns, searchValue) : groupedColumns),
    [groupedColumns, searchValue],
  );

  const toggleColumn = (field, checked) => {
    apiRef.current.setColumnVisibility(field, checked);
  }

  const handleSearch = debounce((searchValue) => {
    setSearchValue(searchValue.trim().toLowerCase());
  }, 300);

  const filterColumns = (allCols, searchValue) =>
    !searchValue
      ? allCols
      : allCols.reduce((res, [section, cols]) => {
        const filteredCols = cols.filter(x => x.headerName.toLowerCase().includes(searchValue));

        if (filteredCols.length) {
          res.push([section, filteredCols]);
        }
        return res;
      }, []);

  return (
    <Box className="flex flex-col max-w-5xl relative my-2 mx-2.5">
      <StyledTextField
        onChange={(e) => handleSearch(e.target.value)}
        label="Find column"
        type="search"
        variant="filled"
        size="small"
        classes={{ root: 'mb-[10px]' }}
        InputProps={{
          className: 'text-[#666666] bg-white',
        }}
      />
      <Box className="overflow-y-auto">
        {
          filteredColumns.map(([section, columns]) => {
            const id = `${section}-content`;

            return (
              <Accordion
                key={section}
                className="shadow-none"
                classes={{ root: 'before:h-0', expanded: 'm-0' }}
              >
                <AccordionSummary
                  className="w-min min-h-[22px] border-0"
                  classes={{ content: 'm-0' }}
                  expandIcon={<ExpandMoreIcon className="w-6 h-4" />}
                  aria-controls={id}
                  id={id}
                >
                  <Typography
                    className="uppercase text-[10px] font-medium text-[#666] whitespace-nowrap"
                  >
                    {section}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box className="flex flex-col">
                    {
                      columns?.map(({ field, hide, headerName }) => (
                        <FormControlLabel
                          key={id + headerName}
                          control={
                            <Switch
                              checked={!hide}
                              onChange={(e) => toggleColumn(field, e.target.checked)}
                              classes={{
                                track: hide ? '' : 'bg-[#cc0000] opacity-100',
                                thumb: 'bg-white',
                              }}
                            />
                          }
                          label={headerName}
                          classes={{ label: 'text-[15px]' }} />
                      ))
                    }
                  </Box>
                </AccordionDetails>
              </Accordion>
            );
          })
        }
      </Box>
    </Box>
  );
}

export default ColumnsVisibilityPanel;