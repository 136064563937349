/**************************************************************************
 *
 *     Copyright Bain & Company. 2020, 2021
 *
 **************************************************************************/
import React from 'react';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material/styles';

import { NotificationCloseAction } from './Components/Shared/Notifications/NotificationCloseAction';

import { GlobalCss } from './globalStyle';
import { Main } from './Components/Main';
import Header from './Components/Header';
import Routes from './Routes';
import { theme } from './theme';
import { CaseCodeDialog } from './Components/Dialogs/CaseCodeDialog';
import { TooManyCompaniesDialog } from './Components/Dialogs/CompanyOverview/TooManyCompaniesDialog';
import { CompanyOverviewDialog } from './Components/Dialogs/CompanyOverview/CompanyOverviewDialog';
import { AdvancedSearchDialog } from './Components/Dialogs/AdvancedSearchDialog';
import { DeleteQueryDialog } from './Components/Dialogs/DeleteQueryDialog';
import CompanyProfileView from './Components/CompanyResearch/CompanyProfile/CompanyProfileView';
import { SearchCompaniesHistoryDialog } from './Components/Dialogs/SearchCompaniesHistoryDialog';

import awsService from './awsService';

import './App.css';

awsService.initializeAws();

const App = () => {
  return (
    <Main>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={4500}
          preventDuplicate
          action={NotificationCloseAction}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Header></Header>
          <Routes />

          {/** Render modals here */}
          <CompanyOverviewDialog />
          <TooManyCompaniesDialog />
          <CaseCodeDialog />
          <AdvancedSearchDialog />
          <DeleteQueryDialog />
          <CompanyProfileView />
          <SearchCompaniesHistoryDialog />
          {/** Render modals here */}

          <GlobalCss />
        </SnackbarProvider>
      </ThemeProvider>
    </Main>
  );
};

export default App;
