import { groupBy } from 'lodash';

export const pluck = (path, data) => data.map((item) => _.get(item, path));

export const groupAndSortList = (list = [], orderList = [], key = 'id') => {
  const sectionsList = Object.entries(groupBy(list, key));

  sectionsList?.sort((a, b) =>
    orderList.indexOf(a[0]) - orderList.indexOf(b[0]),
  );

  return sectionsList;
}
