import { getDecoratedTerm } from '@/Utils/pegjs/astTreeUtils';
import React, { ReactNode } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import classnames from 'classnames';
import { isEmpty } from 'lodash';

import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { useModal } from '@/hooks/use-modal';
import { MODALS } from '@/constants';
import { AstLeaf, AstNode, AstTree, Position } from '@/types/ast-tree-models';
import { Nullish } from '@/types';

interface QueryBlockDetails {
  term: Nullish<string>;
  isKeyWord?: boolean;
  isKeyChar?: boolean;
  position?: Position;
}

export const SavedQueries = () => {
  const { handleOpen: openDeleteQueryModal } = useModal(MODALS.DELETE_QUERY);
  const { handleOpen: openAdvancedSearchModal } = useModal(MODALS.ADVANCED_SEARCH);
  const searchQueryTree = useShallowSelector(({ search }) => search.searchQueryTree);
  const { isError } = useShallowSelector(({ search }) => search.bainIds);

  let key = 0;

  const handleRemove = (position?: Position) => (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    openDeleteQueryModal(position);
  };

  const handleClick = () => {
    openAdvancedSearchModal();
  };

  const getQueryBlock = ({ term, isKeyWord, isKeyChar, position }: QueryBlockDetails): ReactNode => {
    return (
      <div
        key={key++}
        onClick={handleClick}
        className={classnames('w-fit flex items-center bg-[#ebf9f2] p-1 rounded text-xs cursor-pointer', {
          'bg-[#eae6ff]': isKeyWord,
          'bg-[#e7cede]': isKeyChar,
        })}
      >
        <p className="text-left">{term}</p>

        {!isKeyChar && !isKeyWord && (
          <IconButton
            className="w-4 h-4 ml-1"
            onClick={handleRemove(position)}
          >
            <CloseIcon className="w-4	h-4" />
          </IconButton>
        )}
      </div>
    );
  };

  const astTreeToView = (node: AstNode) => {
    if (!node || isEmpty(node)) {
      return [];
    }

    let blocks: ReactNode[] = [];

    const { left, operator, right, parenthesized } = node as AstTree;
    const { field, term, similarity, quoted, prefix, boost, fieldLocation, termLocation } = node as AstLeaf;

    if (!left) {
      if (field && field !== '<implicit>') {
        blocks.push(getQueryBlock({ term: `${field}:`, position: fieldLocation?.start }));
      }

      const displayTerm = getDecoratedTerm(term)(quoted)(prefix)(similarity)(boost);

      blocks.push(getQueryBlock({ term: displayTerm, position: termLocation?.start }));

      return blocks;
    }

    blocks.push(...astTreeToView(left));

    if (operator && operator !== '<implicit>') {
      blocks.push(getQueryBlock({ term: operator, isKeyWord: true }));
    }

    if (right) {
      blocks.push(...astTreeToView(right));
    }

    if (parenthesized) {
      blocks = [
        getQueryBlock({ term: '(', isKeyChar: true }),
        ...blocks,
        getQueryBlock({ term: ')', isKeyChar: true }),
      ];
    }

    if (field && field !== '<implicit>') {
      blocks = [getQueryBlock({ term: `${field}:`, position: fieldLocation?.start }), ...blocks];
    }

    return blocks;
  };

  const showSavedQueries = !isError && !isEmpty(searchQueryTree);

  return showSavedQueries && <div className="flex flex-wrap gap-3 mt-4 ml-2">{astTreeToView(searchQueryTree)}</div>;
};
