import { combineReducers } from '@reduxjs/toolkit';

import { reducer as caseCodeReducer } from './case-code';
import { reducer as userReducer } from './user';
import { reducer as customScreenReducer } from './custom-screen';
import { reducer as modalReducer } from './modal';
import { reducer as searchReducer } from './search';
import { reducer as companyProfileReducer } from './company-profile';
import { reducer as filtersReducer } from './filters';

export const rootReducer = combineReducers({
  user: userReducer,
  caseCode: caseCodeReducer,
  customScreen: customScreenReducer,
  modal: modalReducer,
  search: searchReducer,
  companyProfile: companyProfileReducer,
  filters: filtersReducer,
});
