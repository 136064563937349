/**************************************************************************
*
*     Copyright Bain & Company. 2020, 2021
*                                       
**************************************************************************/
import React, { Fragment } from "react";
import makeStyles from '@mui/styles/makeStyles';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Grid, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import styled from "styled-components";
import _ from 'lodash';

import { dataNotAvailablePage } from "../../Shared/common";
import "../../../App.css";

const StyledGrid = styled(Grid)(({ theme }) => ({
  "&.MuiGrid-grid-xs-4": {
    flexGrow: '0',
    maxWidth: '33.333333%',
    flexBasis: '33.333333%',
  },
  "&.MuiGrid-grid-xs-8": {
    flexGrow: '0',
    maxWidth: '66.666667%',
    flexBasis: '66.666667%',
  }
}))

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  tabsHeading: {
    letterSpacing: "wide",
    fontSize: "21px !important",
    color: "#CC0000",
    marginTop: "8px",
    backgroundColor: "lightgrey",
    justifyContent: "center",
    display: "flex",
    fontFamily: "Graphik, sans-serif",
  }
});

const execTeamColumns = [
  { field: "firstName", headerName: "First Name", width: 180 },
  { field: "lastName", headerName: "Last Name", width: 180 },
  { field: "role", headerName: "Role", width: 260 },
  { field: "startDate", headerName: "Start Date", width: 180 },
  { field: "endDate", headerName: "End Date", width: 180 },
  { field: "timeInRole", headerName: "Time in role", width: 180 },
  { field: "age", headerName: "Age", type: "number", width: 130 },
];

const boardMembersColumns = [
  { field: "firstName", headerName: "First Name", width: 180 },
  { field: "lastName", headerName: "Last Name", width: 180 },
  { field: "role", headerName: "Role", width: 260 },
  { field: "startDate", headerName: "Start Date", width: 180 },
  { field: "endDate", headerName: "End Date", width: 180 },
  { field: "timeInRole", headerName: "Time in role", width: 180 },
  { field: "age", headerName: "Age", type: "number", width: 130 }
];

function parseExecTeamData(input) {
  let rows = input.reduce(
    (rowData, row, id) =>
      rowData.concat({
        id: id,
        firstName:
          row.execs_ppl_Forename1 && row.execs_ppl_Forename1 !== null
            ? row.execs_ppl_Forename1
            : "N/A",
        lastName:
          row.execs_ppl_Surname && row.execs_ppl_Surname !== null
            ? row.execs_ppl_Surname
            : "N/A",
        role:
          row.execs_ppl_RoleName && row.execs_ppl_RoleName !== null
            ? row.execs_ppl_RoleName
            : "N/A",
        timeInRole:
          row.execs_ppl_RoleTenure && row.execs_ppl_RoleTenure !== null
            ? Number(row.execs_ppl_RoleTenure).toFixed(1) + " yrs"
            : "N/A",
        age:
          row.execs_ppl_Age && row.execs_ppl_Age !== null
            ? Math.trunc(row.execs_ppl_Age)
            : "N/A",

        startDate:
          row.execs_ppl_DateStartRole && row.execs_ppl_DateStartRoleDisplay === "n.a."
            ? "N/A"
            : row.execs_ppl_DateStartRole,

        endDate:
          row.execs_ppl_DateEndRoleDisplay &&
            row.execs_ppl_DateEndRoleDisplay !== "n.a."
            ? row.execs_ppl_DateEndRoleDisplay
            : "N/A",
      }),
    []
  );
  return rows;
}

function parseBoardMembersData(input) {
  let rows = input.filter(({ execs_ppl_BrdPosition }) => execs_ppl_BrdPosition != 'No')
    .reduce(
      (rowData, row, id) =>
        rowData.concat({
          id: id,
          firstName:
            row.execs_ppl_Forename1 && row.execs_ppl_Forename1 !== null
              ? row.execs_ppl_Forename1
              : "N/A",
          lastName:
            row.execs_ppl_Surname && row.execs_ppl_Surname !== null
              ? row.execs_ppl_Surname
              : "N/A",
          role:
            row.execs_ppl_RoleName && row.execs_ppl_RoleName !== null
              ? row.execs_ppl_RoleName
              : "N/A",
          timeInRole:
            row.execs_ppl_RoleTenure && row.execs_ppl_RoleTenure !== null
              ? Number(row.execs_ppl_RoleTenure).toFixed(1) + " yrs"
              : "N/A",
          age:
            row.execs_ppl_Age && row.execs_ppl_Age !== null
              ? Math.trunc(row.execs_ppl_Age)
              : "N/A",

          startDate:
            row.execs_ppl_DateStartRole && row.execs_ppl_DateStartRole === "n.a."
              ? "N/A"
              : row.execs_ppl_DateStartRole,

          endDate:
            row.execs_ppl_DateEndRoleDisplay &&
              row.execs_ppl_DateEndRoleDisplay !== "n.a."
              ? row.execs_ppl_DateEndRoleDisplay
              : "N/A",
        }),
      []
    );
  return rows;
}

function renderAnnouncements(announcement) {
  return (
    <div style={{ margin: 15 }}>
      <Typography className="announcement-field" variant="h6" id="Announcement">
        <b>Announcement:</b>{" "}
        {announcement.execs_announce_Description
          ? announcement.execs_announce_Description
          : dataNotAvailablePage()}
      </Typography>
      <Typography className="announcement-field" variant="h6" id="ann-company-name">
        <b>Company Name:</b>{" "}
        {announcement.execs_announce_CompanyName
          ? announcement.execs_announce_CompanyName
          : dataNotAvailablePage()}
      </Typography>
      <Typography className="announcement-field" variant="h6" id="director-name">
        <b>Director Name:</b>{" "}
        {announcement.execs_announce_DirectorName
          ? announcement.execs_announce_DirectorName
          : dataNotAvailablePage()}
      </Typography>
      <Typography className="announcement-field" variant="h6" id="ann-role">
        <b>Role:</b>{" "}
        {announcement.execs_announce_RoleName
          ? announcement.execs_announce_RoleName
          : dataNotAvailablePage()}
      </Typography>
      <Typography className="announcement-field" variant="h6" id="notification-date">
        <b>Date of notification:</b>{" "}
        {announcement.execs_announce_AnnouncementDate
          ? announcement.execs_announce_AnnouncementDate
          : dataNotAvailablePage()}
      </Typography>
      <Typography className="announcement-field" variant="h6" id="effective-date">
        <b>Effective Date:</b>{" "}
        {announcement.execs_announce_EffectiveDate
          ? announcement.execs_announce_EffectiveDate
          : dataNotAvailablePage()}
      </Typography>
      <hr></hr>
    </div>
  )
}

function ExecutivesTab({ announcements, executiveDetails }) {
  const classes = useStyles();

  if (executiveDetails && announcements) {
    const { data } = executiveDetails;
    let execTableRows = parseExecTeamData(data);
    let boardMemberTableRows = parseBoardMembersData(data);

    const sortedAnnouncements = _.orderBy(announcements.data, ['execs_announce_AnnouncementDate'], ['desc']);

    return (
      <Grid container spacing={2}>
        <StyledGrid className="w-4/12" item xs={4}>
          <Typography
            className={classes.tabsHeading}
            id="announcements-header"
          >
            Latest Executive Announcements:
          </Typography>
          <Paper sx={{
            maxHeight: '75vh', overflow: 'auto', color: 'rgba(0, 0, 0, 0.87)', backgroundColor: '#fff',
            boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
          }}>
            {
              sortedAnnouncements.map((announcement, idx) => (
                <Fragment key={idx}>
                  {renderAnnouncements(announcement)}
                </Fragment>
              ))
            }
          </Paper>
        </StyledGrid>

        <StyledGrid className="w-8/12" item xs={8}>
          <Grid item xs={12}>
            <Typography
              className={classes.tabsHeading}
              id="current-board"
            >
              Current Board Members
            </Typography>

            <div style={{ height: 340, width: "100%" }}>
              <DataGridPro
                rows={boardMemberTableRows}
                columns={boardMembersColumns}
                density={'compact'}
                rowsPerPageOptions={[]}
                hideFooterPagination={true}
                hideFooter={true}
                disableColumnPinning={true}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <Typography
              className={classes.tabsHeading}
              id="executive-team"
            >
              Executive Team
            </Typography>
            <div style={{ height: 340, width: "100%" }}>
              <DataGridPro
                rows={execTableRows}
                density={'compact'}
                columns={execTeamColumns}
                rowsPerPageOptions={[]}
                hideFooterPagination={true}
                hideFooter={true}
                disableColumnPinning={true}
              />
            </div>
          </Grid>
        </StyledGrid>
      </Grid>
    );
  } else {
    return (
      <Typography
        className={classes.tabsHeading}
      >
        Loading...
      </Typography>
    )
  }
}

export default ExecutivesTab;
