/**************************************************************************
*
*     Copyright Bain & Company. 2020, 2021
*                                       
**************************************************************************/
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Modal from "@mui/material/Modal";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Skeleton from '@mui/material/Skeleton';
import styled from "styled-components";
import classnames from 'classnames';
import _ from 'lodash';

import { useShallowSelector } from '../../../hooks/use-shallow-selector';
import { numberFormatterCustom, dataNotAvailablePage } from "../../Shared/common";
import ExportComponent from "../../ExportComponent.decorator";
import MultiyearFinancialsTab from "./MultiyearFinTab";
import ExecutivesTab from "./ExecutivesTab";
import M_n_ATab from "./M_n_ATab";
import NewsTab from "./NewsTab";
import { useModal } from "../../../hooks/use-modal";
import { actions } from '../../../slices/company-profile';
import { CURRENCIES, MODALS } from '../../../constants';

import styles from './CompanyProfileView.module.css';
import '../../../App.css';

const StyledButton = styled(Button)(({ theme }) => ({
  "&.MuiButton-root": {
    backgroundColor: 'rgb(204, 0, 0)',
    textTransform: 'none',
    fontWeight: '600',
    color: 'white',
    padding: '6px 15px',
    borderRadius: '4px',
    "&:hover": {
      backgroundColor: 'rgb(204, 0, 0)'
    }
  },
}))

const StyledTabs = styled(Tabs)(({ theme }) => ({
  '& div.MuiTabs-scroller': {
    '& .MuiTabs-flexContainer': {
      justifyContent: 'center',
    }
  }
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 1440,
    top: '1%',
    left: '1%',
    "@media (min-width: 1200px) and (max-device-width: 1600px)": {
      width: 1170
    },
    backgroundColor: 'white',
    border: "2px solid #000",
    boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)',
    padding: theme.spacing(2, 4, 3),
  },
  modal: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflow: 'hidden',
    height: '100%',
    maxHeight: 500,
    display: 'block'
  },
  header: {
    padding: '12px 0',
    borderBottom: '1px solid darkgrey'
  },
  content: {
    overflow: 'scroll'
  },
  root: {
    flexGrow: 1,
    ".MuiTablePagination-caption:not([id^=mui])::after": {
      content: 'none'
    },
  },
  tabsHeading: {
    letterSpacing: "wide",
    fontSize: "21px !important",
    color: "#CC0000",
    marginTop: "8px",
  },
  ".MuiTablePagination-caption:not([id^=mui])::after": {
    content: 'none',
  },
  tabField: {
    fontSize: "17px !important",
    lineHeight: "1.268",
    fontFamily: "Graphik, sans-serif",
  },
}));

function CompanyProfileView() {
  const dispatch = useDispatch();
  const { isOpen, handleClose, data } = useModal(MODALS.COMPANY_PROFILE);
  const [currentTab, setCurrentTab] = useState(0);
  const [showMoreDescription, setShowMoreDescription] = useState(false);
  const [showSubsidiaries, setShowSubsidiaries] = useState(false);
  const classes = useStyles();
  const { currency, columnMapper } = useShallowSelector(state => state.customScreen);
  const {
    isLoading,
    profile,
    news,
    multiyear,
    subsidiaries,
    mergesAndAcquisitions,
  } = useShallowSelector(state => state.companyProfile);

  const params = data?.params;
  const bainId = params?.row?.bain_id?.toString();

  useEffect(() => {
    if (bainId) {
      dispatch(actions.fetchProfile(bainId))
    }

    return () => {
      dispatch(actions.clearProfile());
      setCurrentTab(0);
    };
  }, [bainId]);

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleShowDescription = () => {
    setShowMoreDescription(!showMoreDescription);
  };

  const buildCustomDecsription = (description) => {
    const link = (
      <a className="read-more-link" id="read-more-link" onClick={handleShowDescription}>
        <p className='companyDescriptionLink'>{showMoreDescription ? 'See Less' : 'See More' }</p>
      </a>
    );

    if (!description || description.length < 150) {
      return (
        <>{description}</>
      )
    } else if (!showMoreDescription) {
      return (
        <>
          {description.substring(0, 150) + '... '}
          {link}
        </>
      )
    } else {
      return (
        <>
          {showMoreDescription && description + ' '}
          {link}
        </>
      )
    }
  }

  const buildCustomSubsidaries = () => {
    const subsidiariesCount = subsidiaries.length;
    const filterModel = {
      items: [{ columnField: 'companyName', operatorValue: 'contains', value: '' }],
    };
    const columns = [
      { field: 'id', headerName: 'ID', width: 90 },
      { field: 'companyName', headerName: 'Company Name', width: 550 }
    ];
    const rows = _.range(subsidiariesCount).map((id) => ({ id, companyName: subsidiaries[id] }));

    const handleClick = () => setShowSubsidiaries(!showSubsidiaries);

    return (
      <div className={showSubsidiaries ? styles.subsidiaries : ''}>
        <Typography className={classnames('flex items-center gap-1.5', classes.tabField)} variant="h5">
          <b>Known Subsidiaries:</b>{" "}
          {
            isLoading
              ? <Skeleton width={30} height={20} className="bg-[#0000000f]" />
              : (
                <>
                  <span>{subsidiariesCount}</span>
                  {subsidiariesCount > 0 && (
                    <a className="read-more-link" id="hide-link" onClick={handleClick}>
                      {showSubsidiaries ? 'Hide' : 'Show'}
                    </a>
                  )}
                </>
              )
          }
        </Typography>

        {(!isLoading && showSubsidiaries) && (
          <DataGridPro
            pagination
            pageSize={1000}
            rowsPerPageOptions={[1000]}
            columns={columns}
            rows={rows}
            rowHeight={35}
            hideFooterPagination={true}
            filterModel={filterModel}
            hideFooter
            width={"auto"}
            checkboxSelection={false}
            disableSelectionOnClick
          />
        )}
      </div>
    )
  }

  return _.isEmpty(data) ? null : (
    <div>
      <Modal
        id="company-modal"
        open={isOpen}
        onClose={handleClose}
        style={{ overflow: 'scroll' }}
        disableEnforceFocus
      >
        <div>
          <Box className={classes.paper}>
            <Typography
              fontSize="h4"
              as="h4"
              className={styles.companyProfile}
              id="company-profile"
            >
              Company Profile: <b>{params.value}</b>
            </Typography>

            <Paper className={classes.root} sx={{
              color: 'rgba(0, 0, 0, 0.87)', backgroundColor: '#fff',
              boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
            }}>
              <StyledTabs
                value={currentTab}
                id="company-tab-container"
                onChange={handleChange}
              >
                <Tab label="Overview" id="overview" className={currentTab === 0 ? styles.profileTabSelected : styles.profileTabUnselected}></Tab>
                <Tab label="Multi-year financials" id="multi-year-financials" className={currentTab === 1 ? styles.profileTabSelected : styles.profileTabUnselected} />
                <Tab label="M & A" id="m-a" className={currentTab === 2 ? styles.profileTabSelected : styles.profileTabUnselected} />
                <Tab label="News" id="news" className={currentTab === 3 ? styles.profileTabSelected : styles.profileTabUnselected} />
                <Tab label="Executives" id="executives" className={currentTab === 4 ? styles.profileTabSelected : styles.profileTabUnselected} />
              </StyledTabs>

              {/* Overview tab starts here */}
              <TabPanel value={currentTab} index={0}>
                <div className="flex flex-wrap">
                  <div className="w-1/2">
                    <Typography className={classes.tabsHeading} id="overview-header">Overview</Typography>
                    <Typography className={classes.tabField} variant="h5" id="overview-name">
                      <b>Name:</b> {params.value}
                    </Typography>
                    <Typography className={classes.tabField} variant="h5" id="overview-company">
                      <b>Top-Level Parent Company:</b>{" "}
                      {params.row.parent_firmo_name___ ? params.row.parent_firmo_name___ : dataNotAvailablePage()}
                    </Typography>
                    <Typography className={classes.tabField} variant="h5" id="overview-founded">
                      <b>Founded:</b>{" "}
                      {params.row.self_firmo_yearFounded___ ? params.row.self_firmo_yearFounded___ : dataNotAvailablePage()}
                    </Typography>
                    <Typography className={classes.tabField} variant="h5" id="overview-description">
                      <b>Company Description: </b>{" "}
                      {buildCustomDecsription(params.row.self_firmo_description___)}
                    </Typography>
                    <Typography className={classes.tabField} variant="h5" id="overview-count">
                      <b>Employee Count:</b>{" "}
                      {params.row.self_firmo_employeeCount___ ? params.row.self_firmo_employeeCount___.toLocaleString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : dataNotAvailablePage()}
                    </Typography>
                    {buildCustomSubsidaries()}
                    <Typography className={classes.tabField} variant="h5" id="overview-website">
                      <b>Website:</b>{" "}
                      {params.row.self_firmo_webpage___ !== "UNKNOWN" ?
                        <a href={`${"https://" + params.row.self_firmo_webpage___}`} id="website-link" target="_blank" rel="noopener noreferrer">{params.row.self_firmo_webpage___}</a>
                        : "Unknown"}
                    </Typography>
                  </div>
                  <div className="w-1/2">
                    <Typography className={classes.tabsHeading} id="location-header">Location</Typography>
                    <Typography className={classes.tabField} variant="h5" id="location-region">
                      <b>Region:</b>{" "}
                      {params.row.self_location_region___ ? params.row.self_location_region___ : dataNotAvailablePage()}
                    </Typography>
                    <Typography className={classes.tabField} variant="h5" id="location-country">
                      <b>Country:</b>{" "}
                      {params.row.self_location_country___ ? params.row.self_location_country___ : dataNotAvailablePage()}
                    </Typography>
                    <Typography className={classes.tabField} variant="h5" id="location-city">
                      <b>City:</b>{" "}
                      {params.row.self_location_city___ ? params.row.self_location_city___ : dataNotAvailablePage()}
                    </Typography>
                  </div>
                  <div className="w-1/2">
                    <Typography className={classes.tabsHeading} id="financial-highlights-header">Financial Highlights</Typography>
                    <Typography className={classes.tabField} variant="h5" id="financial-revenue">
                      <b>Revenue:</b>{" "}
                      {params.row.self_financials_revenue___ ? CURRENCIES[currency].label + numberFormatterCustom(params.row.self_financials_revenue___) : dataNotAvailablePage()}
                    </Typography>
                    <Typography className={classes.tabField} variant="h5" id="financial-profile">
                      <b>Revenue Profile:</b>{" "}
                      {params.row.self_financials_revenueProfile___ ? params.row.self_financials_revenueProfile___ : dataNotAvailablePage()}
                    </Typography>
                    <Typography className={classes.tabField} variant="h5" id="financial-1">
                      <b>Revenue CAGR 1 year (%):</b>{" "}
                      {params.row.self_financials_revenueCagr_1yr__ ? params.row.self_financials_revenueCagr_1yr__.toFixed(0) : dataNotAvailablePage()}
                    </Typography>
                    <Typography className={classes.tabField} variant="h5" id="financial-3">
                      <b>Revenue CAGR 3 year (%):</b>{" "}
                      {params.row.self_financials_revenueCagr_3yr__ ? params.row.self_financials_revenueCagr_3yr__.toFixed(0) : dataNotAvailablePage()}
                    </Typography>
                    <Typography className={classes.tabField} variant="h5" id="financial-ebitda">
                      <b>EBITDA Margin (%):</b>{" "}
                      {params.row.self_financials_ebitdaMargin___ ? params.row.self_financials_ebitdaMargin___.toFixed(0) : dataNotAvailablePage()}
                    </Typography>
                    <Typography className={classes.tabField} variant="h5" id="financial-net">
                      <b>Net Income Margin (%):</b>{" "}
                      {params.row.self_financials_netIncomeMargin___ ? params.row.self_financials_netIncomeMargin___.toFixed(0) : dataNotAvailablePage()}
                    </Typography>
                  </div>
                  <div className="w-1/2">
                    <Typography className={classes.tabsHeading} id="industry-header">Industry & Sector</Typography>
                    <Typography className={classes.tabField} variant="h5" id="industry-sector">
                      <b>Sector:</b>{" "}
                      {params.row.self_sector_sector___ ? params.row.self_sector_sector___ : dataNotAvailablePage()}
                    </Typography>
                    <Typography className={classes.tabField} variant="h5" id="industry-group">
                      <b>Industry Group:</b>{" "}
                      {params.row.self_sector_industryGroup___ ? params.row.self_sector_industryGroup___ : dataNotAvailablePage()}
                    </Typography>
                    <Typography className={classes.tabField} variant="h5" id="industry">
                      <b>Industry:</b>{" "}
                      {params.row.self_sector_industry___ ? params.row.self_sector_industry___ : dataNotAvailablePage()}
                    </Typography>
                    <Typography className={classes.tabField} variant="h5" id="industry-sub">
                      <b>Sub Industry:</b>{" "}
                      {params.row.self_sector_subIndustry___ ? params.row.self_sector_subIndustry___ : dataNotAvailablePage()}
                    </Typography>
                  </div>
                </div>
              </TabPanel>

              <TabPanel value={currentTab} index={1}>
                <Typography className={classes.tabsHeading} id="financial-details-header">Financial Details</Typography>
                <Typography variant="body">The following data is calculated using the calendar year</Typography>
                <Typography variant="h5"></Typography>
                <MultiyearFinancialsTab
                  multiYear={multiyear}
                  columnMapper={columnMapper}
                  isCompanyProfileLoading={isLoading}
                />
              </TabPanel>

              <TabPanel value={currentTab} index={2}>
                <Typography className={classes.tabsHeading} id="merges-header">
                  Merges & Acquisitions Details
                </Typography>
                <M_n_ATab params={params} m_n_a={mergesAndAcquisitions} columnMapper={columnMapper} />
              </TabPanel>

              <TabPanel value={currentTab} index={3}>
                <Typography className={classes.tabsHeading} id="news-header">News</Typography>
                <NewsTab
                  news={news}
                  bainId={bainId}
                />
              </TabPanel>

              <TabPanel value={currentTab} index={4}>
                <ExecutivesTab
                  announcements={profile[0]}
                  executiveDetails={profile[1]}
                />
              </TabPanel>
            </Paper>

            <Box display="flex" justifyContent="flex-end" marginTop="10px">
              <Box mr={2}>
                <ExportComponent
                  selectedFilters={[]}
                  includeNullFilters={[]}
                  bainIds={[params.row.self_ids_id__cdp_.toString()]}
                  isCompanyProfile
                />
              </Box>
              <StyledButton onClick={handleClose} id="close-company-button">
                Close
              </StyledButton>
            </Box>
          </Box>
        </div>
      </Modal>
    </div>
  );
}

export default CompanyProfileView;
