import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import Button from '@mui/material/Button';
import classnames from 'classnames';
import { createEditor, Transforms, Editor } from 'slate';
import { Slate, Editable, withReact, ReactEditor } from 'slate-react';
import { useSearchCompanies } from '../../../hooks/use-search-companies';

import { useModal } from '../../../hooks/use-modal';
import { MODALS } from '../../../constants';
import { useShallowSelector } from '../../../hooks/use-shallow-selector';
// import { decorate, removeQuotes, withCustom } from "../../../Utils/slate";
import { decorate } from '../../../Utils/slate';
import { Keywords } from './Keywords';

const getInitialSlateValue = (text) => [
  {
    type: 'paragraph',
    children: [{ text }],
  },
];

export const AdvancedSearchDialog = () => {
  const searchCompanies = useSearchCompanies();
  const { isOpen, handleClose } = useModal(MODALS.ADVANCED_SEARCH);
  // const [editor] = useState(() => withCustom(withReact(createEditor())));
  const [editor] = useState(() => withReact(createEditor()));
  const [text, setText] = useState('');
  const searchQuery = useShallowSelector((state) => state.search.searchQuery);

  const initialSlateValue = getInitialSlateValue(
    searchQuery.replace(/\s\s+/g, ' '),
  );

  useEffect(() => {
    setText(isOpen ? searchQuery : '');
  }, [isOpen]);

  useEffect(() => {
    const offset = initialSlateValue[0].children[0].text.length;

    /** Move the cursor to the last editor character  */
    Transforms.select(editor, { offset, path: [0, 0] });
  }, [isOpen, searchQuery]);

  const handleChange = (data) => {
    const result = data.reduce(
      (previous, current) => previous + current.children[0].text,
      '',
    );

    setText(result.replace(/\s\s+/g, ' '));
  };

  const handleApplyClick = () => {
    searchCompanies({ isLucene: true, searchText: text }, { useStateFilters: true });
    handleClose();
  };

  const handleClearClick = () => {
    Transforms.delete(editor, {
      at: {
        anchor: Editor.start(editor, []),
        focus: Editor.end(editor, []),
      },
    });
    ReactEditor.focus(editor);
  };

  const handleCloseDialog = () => {
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseDialog}
      classes={{ paper: 'w-9/12 rounded-lg' }}
    >
      <DialogTitle className="text-sm">
        <p className="mb-2 text-base font-bold">Advanced Search</p>
        <p>
          Add in keywords and conditions using the field and buttons below, or
          simply paste in an existing query and we’ll highlight the conditions
          for you.
        </p>
      </DialogTitle>

      <DialogContent className="text-xs">
        <div className="relative p-2 rounded border border-[#ddd]">
          <Slate
            editor={editor}
            value={initialSlateValue}
            onChange={handleChange}
          >
            <Editable
              id="query-editor"
              decorate={decorate}
              className="h-40"
              renderLeaf={({ attributes, children, leaf }) => {
                return (
                  <span
                    className={classnames('bg-[#ebf9f2]', {
                      'text-cgray-150 inline-block rounded text-center':
                        leaf.keyword || leaf.keychar,
                      'w-2': leaf.keychar,
                      'w-8': leaf.keyword,
                      'bg-[#e7cede]': leaf.keychar,
                      'bg-[#eae6ff]': leaf.keyword,
                    })}
                    {...attributes}
                  >
                    {children}
                  </span>
                );
              }}
            />
          </Slate>
          <Keywords editor={editor} />
        </div>

        {/* <div className="mt-10 mb-3 font-bold">Fuzzy search</div>
        <FormControlLabel
          control={<Checkbox />}
          label="Include near matches"
          classes={{
            label: "text-xs text-[#484848]",
          }}
        /> */}

        <div className="mt-10 flex justify-end text-sm">
          <Button
            className="mx-2 text-black underline"
            onClick={handleCloseDialog}
          >
            Close
          </Button>
          <Button
            className={classnames('mx-2', {
              'bg-[#ddd] text-[#484848]': text,
            })}
            variant="contained"
            disabled={!text}
            onClick={handleClearClick}
          >
            Clear
          </Button>
          <Button
            className="mx-2 bg-[#cc0000]"
            variant="contained"
            onClick={handleApplyClick}
          >
            Apply
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
