import { AstTree, AstLeaf, Position, AstNode } from '@/types/ast-tree-models';
import parser from './lucene-grammar';
import { isEmpty } from 'lodash';
import { Nullish } from '@/types';
import { DEFAULT_ERROR_MESSAGE } from '@/constants';

export const isEmptyTree = (tree: AstNode): boolean => {
  const { term } = (tree ?? {}) as AstLeaf;
  const { right, left } = (tree ?? {}) as AstTree;

  return (!right || isEmptyTree(right)) && (!left || isEmptyTree(left)) && !term;
};

export const getDecoratedTerm = (term: Nullish<string>) => (quoted: Nullish<boolean>) => {
  let decorated = quoted ? `"${term}"` : term;

  return (prefix: Nullish<string>) => {
    decorated = prefix ? `${prefix}${decorated}` : decorated;

    return (similarity: Nullish<number>) => {
      decorated = similarity ? `${decorated}~${similarity === 0.5 ? '' : similarity}` : decorated;

      return (boost: Nullish<number>) => {
        return boost ? `${decorated}^${boost}` : decorated;
      };
    };
  };
};

export const astTreeToString = (node: AstNode) => {
  // convert PEG tree to string
  if (!node || isEmpty(node)) {
    return '';
  }

  const { left, operator, right, parenthesized } = node as AstTree;
  const { field, term, similarity, quoted, prefix, boost } = node as AstLeaf;

  let query = '';

  if (!left) {
    if (field && field !== '<implicit>') {
      query += `${field}:`;
    }

    query += getDecoratedTerm(term)(quoted)(prefix)(similarity)(boost);

    return query.trim();
  }

  query += astTreeToString(left);

  if (operator && operator !== '<implicit>') {
    query += ` ${operator}`;
  }

  if (right) {
    query += ` ${astTreeToString(right)}`;
  }

  if (parenthesized) {
    query = `(${query})`;
  }

  if (field && field !== '<implicit>') {
    query = `${field}:${query}`;
  }

  return query.trim();
};

export const parseQueryToAstTree = (query: string): AstTree => parser.parse(query);

export const fixAstTree = (tree: AstTree) => {
  // fix logic is following:
  // 1. if left operator was deleted, move right to left
  // 2. if no-operator parenthesis are detected, remove parenthesis
  if (isEmpty(tree.left) && isEmptyTree(tree.right as AstTree)) {
    return;
  }

  if (!tree.right || isEmptyTree(tree.right)) {
    // leave left only, skip operator
    tree.operator = null;
    tree.right = null;
  } else {
    fixAstTree(tree.right as AstTree);
  }

  if (!tree.left || isEmptyTree(tree.left)) {
    // move right to left, skip operator
    tree.left = tree.right;
    tree.operator = null;
    tree.right = null;
  } else {
    fixAstTree(tree.left as AstTree);
  }
};

export const removeNodeFromAstTree = (tree: AstTree, { offset, line }: Position) => {
  // remove clause pointed
  const right = tree.right as AstLeaf;
  const left = tree.left as AstLeaf;

  if (
    (left?.termLocation?.start?.line === line && left?.termLocation?.start?.offset === offset) ||
    (left?.fieldLocation?.start?.line === line && left?.fieldLocation?.start?.offset === offset)
  ) {
    tree.left = null;
  } else if (left && !left.term) {
    // run recursive removal
    removeNodeFromAstTree(tree.left as AstTree, { offset, line });
  }

  if (
    (right?.termLocation?.start?.line === line && right?.termLocation?.start?.offset === offset) ||
    (right?.fieldLocation?.start?.line === line && right?.fieldLocation?.start?.offset === offset)
  ) {
    tree.right = null;
  } else if (right && !right.term) {
    // run recursive removal
    removeNodeFromAstTree(tree.right as AstTree, { offset, line });
  }
};

export const removeNodeFromAstTreeAndFix = (tree: AstTree, position: Position): { query: string; tree: AstTree } => {
  const newTree = JSON.parse(JSON.stringify(tree));

  removeNodeFromAstTree(newTree, position);

  if (isEmptyTree(newTree)) {
    return { query: '', tree: {} };
  }

  fixAstTree(newTree);

  const query = astTreeToString(newTree);
  const recalculatedTree = parseQueryToAstTree(query); // recalculate tree to update offsets

  return { query, tree: recalculatedTree };
};

export const normalizeQueryString = (query: string): string => astTreeToString(parseQueryToAstTree(query));

// Coerces unicode quotation marks to standard ascii quotation marks
// to avoid syntax errors in Lucene query when queries are copied over
// from Microsoft Office suite.
//
// This is heuristic to enable common business workflow:
// - refining queries in Word / Excel / OneNote
// - copying queries from those tools to Helix Find
export const sanitizeQueryString = (query: string): string => query.replace(/[“”]/g, '"');

export const guessUserFriendlyLuceneError = (searchQuery: string) => {
  const errors: string[] = [];

  const symbols = {
    parentheses: { open: '(', closed: ')', closeable: true, balance: 0 },
    quotes: { token: '"', closeable: false, count: 0 },
  };

  for (const symbol in symbols) {
    searchQuery.split('').forEach((char) => {
      const currentSymbolObject = symbols[symbol as keyof typeof symbols];

      if (currentSymbolObject['closeable']) {
        if ('open' in currentSymbolObject && char === currentSymbolObject['open']) {
          currentSymbolObject['balance'] += 1;
        }

        if ('closed' in currentSymbolObject && char === currentSymbolObject['closed']) {
          currentSymbolObject['balance'] -= 1;
        }
      } else {
        if ('token' in currentSymbolObject && char === currentSymbolObject['token']) {
          currentSymbolObject['count'] += 1;
        }
      }
    });
  }

  if (symbols['quotes']['count'] % 2 !== 0) {
    errors.push('Make sure to add quotation marks around each search term');
  }

  if (symbols['parentheses']['balance'] !== 0) {
    errors.push('Make sure to close your bracket groups');
  }

  if (errors.length === 0) {
    errors.push(DEFAULT_ERROR_MESSAGE);
  }

  return errors.join(', ');
};
