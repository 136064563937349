import { configureStore } from '@reduxjs/toolkit';

import { rootReducer } from '@/slices';
import { IS_DEV } from '@/constants';

export const store = configureStore({
  reducer: rootReducer,
  devTools: IS_DEV,
});

export type TRootState = ReturnType<typeof store.getState>;
export type TAppDispatch = typeof store.dispatch;
