/**************************************************************************
 *
 *     Copyright Bain & Company. 2020, 2021
 *
 **************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { Box, Button, Dialog, DialogActions, DialogTitle, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import API from '@aws-amplify/api-rest';
import { Close } from '@mui/icons-material';
import { take } from 'rxjs';
import styled from 'styled-components';
import classnames from 'classnames';
import _ from 'lodash';
import Tooltip from '@mui/material/Tooltip';
import awsService from '../awsService';
import { getFilters } from '../Utils/filters';
import { actions } from '../slices/modal';
import { MODALS } from '../constants';
import { NotificationMessage } from './Shared/Notifications/NotificationMessage';
import { ExportButton } from './Shared/Buttons/ExportButton';

import './ExportComponent.css';
import { searchSubject } from '../Utils/subjects';

const StyledRadio = styled(Radio)(({ theme }) => ({
  '&.MuiButtonBase-root': {
    padding: '9px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  '&.MuiButtonBase-root.Mui-disabled': {
    color: 'rgba(0, 0, 0, 0.26)',
  },
  '&.Mui-checked': {
    color: '#c00',
  },
}));

const MAX_OVERVIEW_COMPANIES = 5;

class ExportComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveQuery: this.props.saveQuery,
      selectedFilters: this.props.selectedFilters,
      dialogOpen: false,
      noCaseCode: !this.props.caseCodeFormData.caseCode,
      caseCode: this.props.caseCodeFormData.caseCode,
      caseType: this.props.caseCodeFormData.caseType,
      projectName: this.props.caseCodeFormData.projectName,
      projectType: this.props.caseCodeFormData.projectType,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      exportOption: _.isEmpty(props.selectedCompanies) ? 'all' : state.exportOption,
    };
  }

  componentDidMount() {
    awsService.getUser().then((data) => {
      if (data?.username) {
        this.setState({ userName: data.username });
      }
    });
  }

  exportData() {
    const { exportOption } = this.state;
    const {
      selectedCompanies,
      isCompanyProfile,
      includedNullList,
      filters,
      storedFilters,
      searchQuery,
      searchQueryTree,
      location,
      currency,
      bainIds,
      email,
    } = this.props;

    const isCustomScreen = location.pathname === '/customScreen';

    let response;
    let body = {
      email,
      sql_query: true,
      ...(!_.isEmpty(bainIds) && { self_ids_id__cdp_: bainIds }),
      ...(!_.isEmpty(includedNullList) && {
        include_null_list: includedNullList,
      }),
      ...(isCustomScreen && { free_search_text: searchQuery }),
      ...filters,
    };

    if (isCompanyProfile) {
      body.company_profile = true;
      body.bainIds = bainIds;
    } else if (exportOption === 'selected') {
      body.company_profile = true;
      body.bainIds = selectedCompanies.map(({ id }) => String(id));
    }

    // // Add Filters
    // let includeNullList = this.props.includeNullFilters;
    // if (this.props.selectedFilters.length > 0) {
    //   this.props.selectedFilters.forEach(function (item) {
    //     let itemValues = Object.values(item)[0];
    //     let itemKey = Object.keys(item)[0];
    //     // numerical filter
    //     if ("min" in itemValues && !Array.isArray(itemValues)) {
    //       body[itemKey] = itemValues;
    //     }
    //     // select filter
    //     if (Array.isArray(itemValues)) {
    //       if (!itemValues.includes("All")) {
    //         body[itemKey] = itemValues;
    //       }
    //     }
    //   });
    // }

    // // Add null list to body if present
    // if (includeNullList.length > 0) {
    //   body["include_null_list"] = includeNullList;
    // }

    // if (this.props.bainIds.length > 0) {
    //   body["self_ids_id__cdp_"] = this.props.bainIds;
    // }

    // add case code
    if (this.state.noCaseCode) {
      body['project_name'] = this.state.projectName;
      body['case_type'] = this.state.caseType;
      body['project_type'] = this.state.projectType;
    } else {
      body['case_code'] = this.state.caseCode;
    }

    // // remove this until functional
    // delete body["has_pe_owner"];

    try {
      response = API.post('CDPAPI', `/midtier/export?currency=${currency}`, {
        body: body,
      });
      this.props.enqueueSnackbar(
        <NotificationMessage
          title="Success! You will receive an email with a link to your export."
          description="Exports may take upwards of 10 minutes based on the size of the data.
            Please wait before re-attempting to export."
        />,
        { variant: 'success' },
      );
    } catch (err) {
      this.props.enqueueSnackbar(<NotificationMessage title="Error. Please try again or contact support." />, {
        variant: 'error',
      });
    }
    this.setState({ dialogOpen: false });

    if (this.props.saveQuery) {
      // TODO: refactor, this subscription is needed to get isUniq value
      searchSubject.pipe(take(1)).subscribe(({ isUniq }) => {
        this.props.saveQuery({
          searchText: searchQuery,
          companiesCount: exportOption === 'selected' ? selectedCompanies.length : bainIds.filter((x) => +x > 0).length, // possible value: ['-1']
          isLucene: !!searchQueryTree,
          isExport: true,
          filters: storedFilters,
          isUniq,
        });
      });
    }
  }

  dialogHandleClickOpen = () => {
    this.setState({ dialogOpen: true });
  };

  dialogHandleClose = () => {
    this.setState({ dialogOpen: false });
  };

  handleExportOption = (_, value) => {
    this.setState({ exportOption: value });
  };

  handleNoCaseCode = () => {
    this.setState({ noCaseCode: !this.state.noCaseCode });

    this.state.noCaseCode
      ? this.setState({
          projectName: '',
          caseType: '',
          projectType: 'external',
        })
      : this.setState({ caseCode: '' });
  };

  isNotNilOrEmpty = (val) => val !== undefined && val !== null && val !== '';

  handleCaseCode = (event) => this.setState({ caseCode: event.target.value });

  handleProjectName = (event) => this.setState({ projectName: event.target.value });

  handleCaseType = (event) => this.setState({ caseType: event.target.value });

  handleProjectType = (event) => this.setState({ projectType: event.target.value });

  isCaseCodeValid = () =>
    this.state.noCaseCode
      ? this.isNotNilOrEmpty(this.state.projectName) && this.isNotNilOrEmpty(this.state.caseType)
      : this.isNotNilOrEmpty(this.state.caseCode);

  handleCompanyOverview = () => {
    const isTooMuch = Object.keys(this.props.selectedCompanies).length > MAX_OVERVIEW_COMPANIES;

    this.props.openModal({
      id: isTooMuch ? MODALS.TOO_MANY_COMPANIES : MODALS.COMPANY_OVERVIEW,
    });
  };

  render() {
    const { exportOption } = this.state;
    const { selectedCompanies } = this.props;
    const areIdsEmpty = _.isEmpty(selectedCompanies);

    return (
      <div data-testid="exportComponent">
        {!this.props.isCompanyProfile && (
          <Tooltip
            title="Select one or more companies to export the company comparison slides"
            placement="left"
          >
            <span>
              <Button
                className={classnames('py-2 px-3 mr-4', {
                  'bg-[#ddd]': areIdsEmpty,
                  'bg-[#cc0100] text-white': !areIdsEmpty,
                })}
                disabled={areIdsEmpty}
                onClick={this.handleCompanyOverview}
              >
                Create overview
              </Button>
            </span>
          </Tooltip>
        )}
        <ExportButton
          id="download-button"
          onClick={this.dialogHandleClickOpen}
        />
        <Dialog
          open={this.state.dialogOpen}
          onClose={this.dialogHandleClose}
          id="dialog-box"
        >
          <div style={{ backgroundColor: 'white', position: 'absolute', right: '5px', padding: '10px' }}>
            <Close
              id="close-icon"
              onClick={this.dialogHandleClose}
              sx={{ cursor: 'pointer', color: 'black' }}
            />
          </div>
          <DialogTitle
            sx={{
              backgroundColor: 'white',
              color: 'black',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '70px',
            }}
          >
            <Typography>
              <b>Export Results</b>
            </Typography>
            {this.props.isCompanyProfile ? (
              <Typography
                variant="body1"
                gutterBottom
              >
                Please select "Export" to export all data for the selected company.
              </Typography>
            ) : (
              <Typography
                variant="body1"
                gutterBottom
              >
                Please select an export option:
              </Typography>
            )}
          </DialogTitle>
          <DialogActions
            sx={{ width: '500px', backgroundColor: 'white', color: 'black', padding: '15px' }}
            id="dialog-actions"
          >
            {!this.props.isCompanyProfile ? (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <RadioGroup
                    aria-label="export"
                    name="export"
                    value={exportOption}
                    onChange={this.handleExportOption}
                    style={{
                      position: 'relative',
                    }}
                    id="radio-group"
                  >
                    <FormControlLabel
                      value="all"
                      control={<StyledRadio id="export-all-radio" />}
                      label="Export summary data for all search results (excludes time-series data on financials, news, M&A, others...)"
                      id="export-all"
                    />
                    <FormControlLabel
                      value="selected"
                      control={<StyledRadio id="export-selected-radio" />}
                      disabled={areIdsEmpty}
                      label="Export all data for only selected companies"
                      id="export-selected"
                    />
                  </RadioGroup>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    variant="body1"
                    gutterBottom
                  >
                    {this.isCaseCodeValid() ? '' : '* Starred fields are mandatory'}
                  </Typography>

                  <ExportButton
                    id="export-button-1"
                    onClick={() => this.exportData()}
                  />
                </Box>
              </Box>
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ExportButton
                  id="export-button-2"
                  onClick={() => this.exportData()}
                />
              </Box>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withSnackbar(
  withRouter(
    connect(
      (state) => ({
        email: state.user.email,
        caseCodeFormData: state.caseCode.formData,
        selectedCompanies: state.customScreen.selectedCompanies,
        searchQuery: state.search.searchQuery,
        searchQueryTree: state.search.searchQueryTree,
        isLuceneQuery: state.search.isLuceneQuery,
        currency: state.customScreen.currency,
        filters: getFilters(state.filters),
        storedFilters: state.filters,
        includedNullList: state.filters.includedNullList,
      }),
      { openModal: actions.openModal },
    )(ExportComponent),
  ),
);
