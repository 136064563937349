import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import { updateFilterWith } from './thunks';

type TFilter = string[] | { min: number, max: number};

const initialState = {
  treeFilters: {} as Record<string, string>,
  otherFilters: {} as Record<string, TFilter>,
  includedNullList: [] as string[],
};

const slice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilter: (state, { payload: { id, data } }) => {
      state.otherFilters[id] = data;
    },
    setFilterTree: (state, { payload: { id, data } }) => {
      state.treeFilters[id] = data;
    },
    setFilters: (state, { payload }) => {
      return { treeFilters: state.treeFilters, includedNullList: [], ...payload };
    },
    setIdToNullList: (state, { payload }) => {
      state.includedNullList.includes(payload)
        ? state.includedNullList = _.without(state.includedNullList, payload)
        : state.includedNullList.push(payload)
    },
    addIdToNullList: (state, { payload }) => {
      state.includedNullList = _.uniq([...state.includedNullList, payload]);
    },
    setIdsToNullList: (state, { payload }) => {
      state.includedNullList = _.uniq([...state.includedNullList, ...payload]);
    },
    removeFilter: (state, { payload }) => {
      _.unset( state.otherFilters, payload);
    },
    removeFilterTree: (state, { payload }) => {
      _.unset( state.treeFilters, payload);
    },
    resetFilters: () => {
      return initialState;
    },
  },
});

export const { reducer } = slice;
export const actions = {
  ...slice.actions,
  updateFilterWith,
};

export type TState = typeof initialState;