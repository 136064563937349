import { reduce, flow, isEmpty } from 'lodash';

import { HIDE_FILTERS_COUNT } from '@/constants';

export const getCheckedTreeFilters = flow([
  (data) => reduce(data, (acc, item) => acc.concat(item.map(JSON.parse)), []),
  (data) =>
    data.reduce((acc, { key, value }) => {
      (acc[key] || (acc[key] = [])).push(value);

      return acc;
    }, {}),
]);

export const getFilters = ({ treeFilters, otherFilters }) => ({
  ...reduce(
    {
      ...getCheckedTreeFilters(treeFilters),
      ...otherFilters,
    },
    (acc, item, id) => {
      if (!isEmpty(item)) {
        acc[id] = item;
      }

      return acc;
    },
    {},
  ),
});

export const getFiltersForElasticSearch = (filters) =>
  reduce(getFilters(filters), (acc, item, id) => acc.concat({ [id]: item }), []);

export const getAvailableFilterIdsWithType = (availableFilters) =>
  availableFilters.reduce((acc, filter) => {
    filter.items.forEach(({ backendName, __type__ }) => {
      acc[backendName] = __type__;
    });

    return acc;
  }, {});

export const getFilterIdsByType = (availableFilters, filters, type) => {
  const availableFilterIdsWithType = getAvailableFilterIdsWithType(availableFilters);

  return reduce(
    filters,
    (acc, item, id) => {
      if (availableFilterIdsWithType[id] === type) {
        acc.push(id);
      }

      return acc;
    },
    [],
  );
};

export const getCurrentFiltersToDisplay = (filters, columnMapper) => {
  const keyObj = filters?.otherFilters ?? {};
  const filtersToDisplay = []; // e.g { label: 'Location', value: '' }

  Object.values(filters?.treeFilters ?? {}).forEach((values) => {
    filtersToDisplay.push(...getTreeFiltersStr(columnMapper, values));
  });

  Object.entries(keyObj).forEach(([beKey, value]) => {
    const info = getColumnInfoBeKey(columnMapper, beKey);

    filtersToDisplay.push({
      label: info['Display Name'],
      value: getFilterValueDisplayStr(value, info['NUMERIC_TYPE'] === '%'),
    });
  });

  if (filters?.includedNullList?.length > 0) {
    filtersToDisplay.push({
      label: 'Filters including null values',
      value: filters.includedNullList.map((x) => getColumnInfoBeKey(columnMapper, x)?.['Display Name']).join(', '),
    });
  }

  return filtersToDisplay;
};

const getColumnInfoBeKey = (columnMapper, beKey) => columnMapper.find((x) => x['Backend Name'] === beKey);

const getColumnDisplayLabel = (columnMapper, beKey) => getColumnInfoBeKey(columnMapper, beKey)?.['Display Name'];

const getFilterValueDisplayStr = (value, isPercentage) => {
  if (Array.isArray(value)) {
    return value.map((x) => (x === 'N' ? '"No"' : x === 'Y' ? '"Yes"' : `"${x}"`)).join(', ');
  }

  if (value.min.toString() || value.max.toString()) {
    return `"min": "${value.min}${isPercentage ? '$' : ''}", "max": "${value.max}${isPercentage ? '$' : ''}"`;
  }

  return value;
};

const getTreeFiltersStr = (columnMapper, values) => {
  const resultsObj = {};

  values.forEach((x) => {
    const { key, value } = JSON.parse(x);

    if (resultsObj[key]) {
      resultsObj[key].values.push(value);
    } else {
      resultsObj[key] = {
        label: getColumnDisplayLabel(columnMapper, key),
        values: [value],
      };
    }
  });

  return Object.values(resultsObj).map(({ label, values }) => ({ label, value: values.join(', ') }));
};

export const getCategoricalFilterValue = (value) => (value === null ? 'NULL' : value);

export const prepareFilterTree = ({
  data,
  nodeIdx = '',
  categoryFilterId,
  Icon,
}) =>
  data.map((node, idx) => ({
    key: JSON.stringify({
      id: `${nodeIdx}-${idx}`,
      key: node._backend,
      value: node.value,
      hasChildren: !!node?.children,
      categoryFilterId: categoryFilterId ?? node.categoryFilterId,
    }),
    title: HIDE_FILTERS_COUNT
      ? `${node.value}`
      : `${node.value} (${new Intl.NumberFormat().format(node.active)})`,
    icon: Icon,
    ...(node?.children && {
      children: prepareFilterTree({
        data: node.children,
        nodeIdx: `${nodeIdx}-${idx}`,
        categoryFilterId: categoryFilterId ?? node.categoryFilterId,
        Icon,
      }),
    }),
  }));
