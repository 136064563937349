import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '@aws-amplify/api-rest';

import { IS_DEV } from '@/constants';
import { TRootState } from '@/store';
import { actions, TFormData } from '..';

type TData = {
  hasCode: boolean;
  formData: TFormData;
};

export const loggingCaseCode = createAsyncThunk(
  'caseCode/logging',
  async ({ hasCode, formData }: TData, { dispatch, getState }) => {
    try {
      const {
        user: { email },
      } = getState() as TRootState;
      const data = { email, sql_query: true };

      const body = hasCode
        ? {
            case_code: formData.caseCode,
            ...data,
          }
        : {
            case_type: formData.caseType,
            project_name: formData.projectName,
            project_type: formData.projectType,
            ...data,
          };

      await API.post('CDPAPI', '/log_user_data', { body: body });

      dispatch(actions.setFormData(formData));
      dispatch(actions.closeDialog());

      if (formData.caseCode && !IS_DEV) {
        pendo.track('User Case Code registration', {
          caseCode: formData.caseCode,
        });
      }
    } catch (error) {
      alert(error);
    }
  },
);
