import React, { useState, useMemo, useEffect } from 'react';
import { reduce, isEmpty } from 'lodash';
import classnames from 'classnames';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import { IconFilters } from '../../Icons/IconFilters';
import { FilterHeader } from './FilterHeader';
import { useQueryAvailableFilters } from '../../../hooks/queries/use-query-available-filters';
import { useShallowSelector } from '../../../hooks/use-shallow-selector';
import { useSearchCompanies } from '../../../hooks/use-search-companies';

export const FiltersDrawer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { data: availableFiltersData, isLoading } = useQueryAvailableFilters();
  const searchText = useShallowSelector((state) => state.search.searchText);
  const hasChange = useShallowSelector((state) => state.search.hasChange);
  const isLucene = useShallowSelector((state) => state.search.isLuceneQuery);
  const searchCompanies = useSearchCompanies();
  const availableFilters = availableFiltersData?.data;
  const allAppliedFilters = useShallowSelector((state) => state.filters);

  const isFilterApplied = useMemo(
    () =>
      reduce(
        { ...allAppliedFilters.treeFilters, ...allAppliedFilters.otherFilters },
        (_, item) => !isEmpty(item),
        false
      ),
    [allAppliedFilters]
  );

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    if (hasChange) {
      searchCompanies({ isLucene, searchText });
    }

    setIsOpen(false);
  };

  return (
    <>
      <Button
        startIcon={<IconFilters fill={isFilterApplied ? '#fff' : undefined} />}
        endIcon={isFilterApplied ? <CheckIcon className="ml-1" /> : null}
        className={classnames('px-3 mr-2', {
          'bg-[#ddd] text-[#484848]': !isFilterApplied,
          'bg-[#2E3F4C] text-white': isFilterApplied,
        })}
        onClick={handleOpen}
      >
        Filters
      </Button>

      <Drawer
        anchor="right"
        open={isOpen}
        onClose={handleClose}
        variants="persistent"
        classes={{
          paper: 'w-[35vw] p-4 shadow-[-2px_0px_4px_rgba(0,0,0,0.25)]',
        }}
      >
        <div className="flex items-center justify-between mb-4">
          <span className="font-semibold">Filters</span>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <div className="flex flex-col gap-2">
          {isLoading ? (
            <Box className="flex justify-center">
              <CircularProgress />
            </Box>
          ) : (
            availableFilters?.map((filter) => (
              <FilterHeader key={filter.displayHeader} filter={filter} />
            ))
          )}
        </div>
      </Drawer>
    </>
  );
};
