export const IS_DEV = process.env.NODE_ENV === 'development';

export const HIDE_ADVANCED_SEARCH = JSON.parse(process.env.REACT_APP_HIDE_ADVANCED_SEARCH);
export const HIDE_NEW_FILTERS = JSON.parse(process.env.REACT_APP_HIDE_NEW_FILTERS);
export const HIDE_FILTERS_COUNT = JSON.parse(process.env.REACT_APP_HIDE_FILTERS_COUNT);

export const CASE_TYPES = [
  { value: 'peg-due-diligence', label: 'PEG - Due Diligence' },
  { value: 'peg-vendor-due-diligence', label: 'PEG - Vendor Due Diligence' },
  { value: 'peg-fund-strategy', label: 'PEG - Fund Strategy' },
  { value: 'peg-sector-screen', label: 'PEG - Sector Screen' },
  { value: 'peg-retainer', label: 'PEG - Retainer' },
  { value: 'peg-post-acquisition', label: 'PEG - Post Acquisition' },
  { value: 'gp-corp-ma-sector-screen', label: 'GP/Corp M&A - Sector Screen' },
  { value: 'gp-corp-ma-due-diligence', label: 'GP/Corp M&A - Due Diligence' },
  { value: 'gp-corp-ma-other', label: 'GP/Corp M&A - Other' },
  { value: 'client-development-peg', label: 'Client Development - PEG' },
  { value: 'client-development-GP', label: 'Client Development - GP' },
  { value: 'ip', label: 'IP' },
  { value: 'other', label: 'Other Case Type' },
];

export const MODALS = {
  COMPANY_OVERVIEW: 'companyOverview',
  TOO_MANY_COMPANIES: 'tooManyCompanies',
  ADVANCED_SEARCH: 'advancedSearch',
  DELETE_QUERY: 'deleteQuery',
  COMPANY_PROFILE: 'companyProfile',
  SEARCH_COMPANIES_HISTORY: 'companySearchHistory',
};

export const SPECIAL_SEARCH_WORDS = ['NOT', 'AND', 'OR'];
export const SPECIAL_SEARCH_CHARS = ['?', '*', '~', '(', ')'];
export const QUOTES = ["'", '"'];

export const CURRENCIES = {
  USD: { value: 'usd', label: '$' },
  EUR: { value: 'euro', label: '€' },
};

export const LOCAL_STORAGE_KEYS = {
  QUERIES_HISTORY: 'queriesHistory',
};

export const FILTER_TYPES = {
  NUMERICAL: 'numerical',
  TREE: 'tree',
  CATEGORICAL: 'categorical',
};

export const FILTER_TYPES_ARRAY = Object.values(FILTER_TYPES);

export const QUERIES_HISTORY_EXPIRATION = 90; // days
export const MAX_SEARCH_HISTORY_QUERIES = 1000;

export const DEFAULT_VISIBLE_COLUMNS = [
  'self_firmo_name___',
  'self_firmo_description___',
  'self_firmo_webpage___',
  'self_location_country___',
  'self_sector_industry___',
  'self_firmo_employeeCount___',
  'self_financials_ebitda___',
  'self_financials_netIncome___',
  'self_financials_revenue___',
  'self_financials_revenueCagr_1yr__',
];

export const SECTIONS_ORDER = [
  'Default',
  'Location',
  'Industry',
  'Firmographics',
  'Financials',
  'M&A',
  'Fragmentation',
  'Executives',
  'Ownership',
  'Parent',
  'Subsidiaries',
  'Investment Themes',
  'Investment Theme - Sector M&A',
  'Investment Theme - Platform Consolidation',
  'Investment Theme - Potential Carve Out',
  'Investment Theme - Debt Maturity',
  'Investment Theme - Generational Change',
  'Investment Theme - Leadership Change',
  'Investment Theme - Growth Ambition',
  'Investment Theme - Internal M&A',
  'News',
  'Others',
];

export const DEFAULT_ERROR_MESSAGE = "Something's not quite right with your search.";
