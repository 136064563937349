/**************************************************************************
 *
 *     Copyright Bain & Company. 2020, 2021
 *
 **************************************************************************/
import React, { Component } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Search from '@mui/icons-material/Search';
import FilterAltOutlined from '@mui/icons-material/FilterAltOutlined';
import Storage from '@mui/icons-material/Storage';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';

import awsService from '../awsService';
import classes from './homePage.module.css';
import '../App.css';

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      navigationScreens: [
        {
          title: 'Screeners',
          contentText:
            'Screeners built and maintained by Helix CoE for Bain teams and clients',
          buttonLabel: 'View demo',
          icon: 'Funnel',
          disabled: false,
          routePath: 'quickScreen',
          label: 'Demo only',
        },
        {
          title: 'Search & Overview',
          contentText:
            'Build custom queries from scratch, access company profiles and create overviews',
          buttonLabel: 'Start',
          icon: 'search',
          disabled: false,
          routePath: 'customScreen',
        },
        {
          title: 'Data',
          contentText:
            'Enrich your existing company lists with CDP data Monitor database health (Admin access required)',
          buttonLabel: 'Explore',
          icon: 'Harddrive',
          disabled: true,
          routePath: '',
        },
      ],
    };
  }

  componentDidMount = () => {
    awsService.getUser().then((data) => {
      if (data?.username) {
        let userName = data.username;
        let userNameStripped = userName.substring(userName.indexOf('_') + 1);
        this.setState({ userName: userNameStripped });
      }
    });
  };

  routeToSelectedPage = (selectedPage) => {
    const { history } = this.props;
    if (selectedPage) history.push(`/${selectedPage}`);
  };

  render() {
    const { navigationScreens, userName } = this.state;

    return (
      <Box p={1} data-testid="homePageComponent">
        <Typography
          variant="h2"
          ml={1}
          mt={10}
          className={classes.welcome}
          id="username"
        >
          Welcome {userName}!
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
          {navigationScreens &&
            navigationScreens.map(
              (
                {
                  title,
                  contentText,
                  icon,
                  disabled,
                  routePath,
                  buttonLabel,
                  label,
                },
                idx
              ) => (
                <Box
                  key={idx}
                  bg="backgroundSurface"
                  p={4}
                  color="#666666"
                  sx={{ backgroundColor: 'rgb(245, 245, 245)' }}
                >
                  <Card
                    variant="outlined"
                    sx={{
                      position: 'relative',
                      maxWidth: 380,
                      padding: '7px',
                      backgroundColor: 'white',
                      color: 'rgb(102, 102, 102)',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      {label ? (
                        <Box
                          sx={{
                            position: 'absolute',
                            top: '20px',
                            right: '20px',
                            backgroundColor: '#0484E7',
                            color: '#FFFFFF',
                            borderRadius: '4px',
                            padding: '3px 4px',
                            fontWeight: 500,
                            fontSize: '12px',
                            lineHeight: '16px',
                          }}
                        >
                          {label}
                        </Box>
                      ) : null}
                      <div className="hmCardIcon">
                        {icon === 'Funnel' && (
                          <FilterAltOutlined
                            className="material-icons"
                            sx={{ fontSize: 70 }}
                          />
                        )}
                        {icon === 'search' && (
                          <Search
                            className="material-icons"
                            sx={{ fontSize: 70 }}
                          />
                        )}
                        {icon === 'Harddrive' && (
                          <Storage
                            className="material-icons"
                            sx={{ fontSize: 70 }}
                          />
                        )}
                      </div>
                      <CardContent sx={{ padding: '0px' }}>
                        <Typography
                          variant="h3"
                          mt={1}
                          mb={2}
                          className={classes.screenTitle}
                          id={`${title}`}
                        >
                          {title}
                        </Typography>
                        <Typography
                          variant="body1"
                          mb={3}
                          className={classes.screenText}
                        >
                          {contentText}
                        </Typography>
                      </CardContent>
                      <CardActions
                        sx={{ width: '95%', justifyContent: 'center' }}
                      >
                        <Button
                          id={`explore-button-${idx}`}
                          onClick={() => this.routeToSelectedPage(routePath)}
                          className={classnames('w-full p-2', {
                            'bg-[#cc0000] text-white': !disabled,
                            'bg-[#0000001f] text-[#00000042]': disabled,
                          })}
                          disabled={disabled}
                        >
                          {buttonLabel}
                        </Button>
                      </CardActions>
                    </Box>
                  </Card>
                </Box>
              )
            )}
        </Box>
      </Box>
    );
  }
}

export default HomePage;
