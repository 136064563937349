import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import _ from 'lodash';

import { StyledTextField } from '../../../../../Shared/FilterComponentStyles';
import { useShallowSelector } from '../../../../../../hooks/use-shallow-selector';
import { actions } from '../../../../../../slices/filters';

function getInputsConfig(numericFilterType) {
  switch (numericFilterType) {
    case NUMERIC_FILTER_TYPES.YEARS:
      return { min: { placeholder: 'From' }, max: { placeholder: 'To' } };
    case NUMERIC_FILTER_TYPES.PERCENTAGE:
      return { min: { placeholder: '%' }, max: { placeholder: '%' } };
    case NUMERIC_FILTER_TYPES.EUR:
      return { min: { placeholder: '€' }, max: { placeholder: '€' } };
    case NUMERIC_FILTER_TYPES.USD:
      return { min: { placeholder: '$' }, max: { placeholder: '$' } };
    case NUMERIC_FILTER_TYPES.OTHER:
      return { min: { placeholder: 'Min' }, max: { placeholder: 'Max' } };
    default:
      return { min: { placeholder: 'Min' }, max: { placeholder: 'Max' } };
  }
}

const NUMERIC_FILTER_TYPES = {
  YEARS: 'year',
  PERCENTAGE: 'percent',
  EUR: 'EUR',
  USD: 'USD',
  OTHER: 'count',
};

const NUMERIC_FILTER_TYPES_ARRAY = Object.values(NUMERIC_FILTER_TYPES);

const NumberFormatPlain = (props) => (
  <NumberFormat {...props} isNumericString />
);
const NumberFormatWithSeparator = (props) => (
  <NumberFormat {...props} thousandSeparator isNumericString />
);

const FilterNumericalInput = ({
  id,
  placeholder,
  type,
  value,
  handleChange,
}) => {
  const applyThousandSeparator = type !== NUMERIC_FILTER_TYPES.YEARS;

  return (
    <StyledTextField
      variant="outlined"
      size="small"
      fullWidth
      key={id}
      id={id}
      name={id}
      value={value}
      InputProps={{
        inputComponent: applyThousandSeparator
          ? NumberFormatWithSeparator
          : NumberFormatPlain,
      }}
      label={placeholder}
      onChange={handleChange}
    />
  );
};

const prepareValue = (value) =>
  _.isEmpty(value) ? 0 : Number(value.replace(/,/g, ''));

export const FilterNumerical = ({ values: { min, max }, type, itemId }) => {
  const dispatch = useDispatch();
  const inputConfig = getInputsConfig(type);
  const filterData = useShallowSelector(
    (state) => state.filters.otherFilters[itemId] ?? { min, max }
  );
  const includedNullList = useShallowSelector(
    (state) => state.filters.includedNullList
  );
  const isChecked = !includedNullList.includes(itemId);

  const handleChange = (type) => ({ target: { value } }) => {
    dispatch(
      actions.updateFilterWith({
        payload: {
          id: itemId,
          data: {
            ...filterData,
            [type]: prepareValue(value),
          },
        },
        action: actions.setFilter,
      })
    );
  };

  const handleChangeNull = () => {
    dispatch(
      actions.updateFilterWith({
        action: actions.setIdToNullList,
        payload: itemId,
      })
    );

    if (isChecked && (filterData.min === min && filterData.max === max)) {
      dispatch(
        actions.updateFilterWith({
          payload: itemId,
          action: actions.removeFilter,
        })
      );
    } else {
      dispatch(
        actions.updateFilterWith({
          payload: { id: itemId, data: filterData },
          action: actions.setFilter,
        })
      );
    }
  };

  return (
    <>
      <div className="flex justify-between gap-4 mr-4 mt-4 mb-2">
        <FilterNumericalInput
          id={`${itemId}-min`}
          placeholder={inputConfig.min.placeholder}
          type={type}
          value={filterData?.min ?? min}
          handleChange={handleChange('min')}
        />
        <FilterNumericalInput
          id={`${itemId}-max`}
          placeholder={inputConfig.max.placeholder}
          type={type}
          value={filterData?.max ?? max}
          handleChange={handleChange('max')}
        />
      </div>
      <FormControlLabel
        id={`${itemId}-nullable`}
        label="Exclude missing values?"
        classes={{
          root: 'text-[#484848]',
        }}
        control={
          <Switch
            classes={{
              root: 'h-[44px] w-[60px] rounded-[10px]',
              track: 'rounded-[10px] opacity-20',
              thumb: 'border border-solid	border-[#DDDDDD] h-[26px] w-[26px]',
            }}
            checked={isChecked}
            onChange={handleChangeNull}
          />
        }
      />
    </>
  );
};

FilterNumerical.propTypes = {
  values: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
  }),
  type: PropTypes.oneOf(NUMERIC_FILTER_TYPES_ARRAY),
  itemId: PropTypes.string.isRequired,
};
