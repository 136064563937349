/**************************************************************************
*
*     Copyright Bain & Company. 2020, 2021
*                                       
**************************************************************************/
import { get, isEqual } from "lodash";
import API from "@aws-amplify/api-rest";
import _ from "lodash";

class commonUtil {
  fetchFiltersData = async (reqBody, url) => {
    try {
      let response = await this.postFilterData(reqBody, url);
      return response;
    } catch (error) {
      const response = error.response.data;

      return _.omit(response, ['error']);
    }
  };

  postFilterData = (filterData, url) => {
    return API.post("CDPAPI", `/${url}`, {
      body: filterData,
    });
  };

  fetchIndexData = async (url) => {
    try {
      let response = await this.getIndexData(url);
      return response;
    } catch (error) {
      return null;
    }
  };

  getIndexData = (url) => {
    return API.get("CDPAPI", `/${url}`);
  };
  //filters

  getInitialReqBody = (dataToRender, isOnload, dataTosend) => {
    let reqBody = Object.create(null, {}),
      hideCity = false;
    if (dataToRender && dataToRender.tabsList) {
      dataToRender.tabsList.forEach((tab) => {
        if (tab && tab.dropDownList) {
          tab.dropDownList.forEach((list) => {
            if (
              list.filterKey === "Country" &&
              list.selectedValue.includes("All")
            ) {
              // hideCity = true;
            }
            if (list.filterKey === "City" && hideCity) {
              //list.hideData = true;
            }
            !list.hideData &&
              this.setReqData(list, reqBody, isOnload, dataTosend);
          });
        } else if (tab && tab.groupList) {
          tab.groupList.forEach((group) => {
            group.dropDownList.forEach((list) => {
              if (
                list.filterKey === "Country" &&
                list.selectedValue.includes("All")
              ) {
                // hideCity = true;
              }
              if (list.filterKey === "City" && hideCity) {
                //list.hideData = true;
              }
              !list.hideData &&
                this.setReqData(list, reqBody, isOnload, dataTosend);
            });
          });
        }
      });
    }
    return reqBody;
  };

  getCmpnyResearchReqBody = (selectedTab, isOnload, dataTosend) => {
    let reqBody = Object.create(null, {});
    if (selectedTab && selectedTab.dropDownList) {
      selectedTab.dropDownList.forEach((list) => {
        !list.hideData && this.setReqData(list, reqBody, isOnload, dataTosend);
      });
    } else if (selectedTab && selectedTab.groupList) {
      selectedTab.groupList.forEach((group) => {
        group.dropDownList.forEach((list) => {
          !list.hideData &&
            this.setReqData(list, reqBody, isOnload, dataTosend);
        });
      });
    }
    return reqBody;
  };

  setReqData = (list, reqBody, isOnload, dataTosend) => {
    if (list && list.backendKey && dataTosend.includes(list.backendKey)) {
      if (Array.isArray(list.selectedValue)) {
        if (list.selectedValue.includes("All")) {
          if (!isOnload) {
            let cloneArray = list.selectedValue.slice();
            cloneArray.splice(cloneArray.indexOf("All"), 1);
            reqBody[list.backendKey] = cloneArray;
          }
        } else {
          reqBody[list.backendKey] =
            list.selectedValue.length > 1
              ? list.selectedValue
              : list.selectedValue[0];
        }
      } else {
        if (list.selectedValue.includes("All")) {
          if (!isOnload) {
            reqBody[list.backendKey] = "";
            // let cloneArray = list.selectedValue.slice();
            // cloneArray.splice(cloneArray.indexOf('All'), 1);
            // reqBody[list.backendKey] = cloneArray;
          }
        } else {
          reqBody[list.backendKey] = list.selectedValue;
        }
      }
    }
  };

  setupInitialData = (dataToRender, backendData) => {
    if (dataToRender && dataToRender.tabsList) {
      dataToRender.tabsList.forEach((tab) => {
        if (tab && tab.dropDownList) {
          tab.dropDownList.forEach((list) => {
            this.setData(backendData, list);
          });
        } else if (tab && tab.groupList) {
          tab.groupList.forEach((group) => {
            group.dropDownList.forEach((list) => {
              this.setData(backendData, list);
            });
          });
        }
      });
    }
  };

  setupCRInitialData = (selectedTab, backendData) => {
    if (selectedTab && selectedTab.dropDownList) {
      selectedTab.dropDownList.forEach((list) => {
        this.setData(backendData, list);
      });
    } else if (selectedTab && selectedTab.groupList) {
      selectedTab.groupList.forEach((group) => {
        group.dropDownList.forEach((list) => {
          this.setData(backendData, list);
        });
      });
    }
  };

  setData = (backendData, list) => {
    if (backendData && backendData[list.backendKey]) {
      //&& list.backendKey != 'city' && list.backendKey != 'country' (add this condition in below if condition when we have more list of countries)
      if (list.selectedRange) {
        let data = {
          min: backendData[list.backendKey].min.toLocaleString(),
          max: backendData[list.backendKey].max.toLocaleString(),
        };
        list.initialValue = data;
        list.selectedRange = data;
      } else if (list.selectedValue) {
        if (Array.isArray(list.selectedValue)) {
          let allFound = true;
          list.selectedValue.forEach((val) => {
            if (!backendData[list.backendKey].includes(val)) {
              allFound = false;
            }
          });
          if (!allFound && backendData[list.backendKey].includes("All")) {
            list.selectedValue = ["All"];
          }
        } else {
          if (!backendData[list.backendKey].includes(list.selectedValue)) {
            list.selectedValue = "All";
          }
        }

        list.values = backendData[list.backendKey];
      }
    } else if (list.selectedRange) {
      let data = {
        min: list.initialValue.min.toLocaleString(),
        max: list.initialValue.max.toLocaleString(),
      };
      //toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      let selectedRangeData = {
        min: list.selectedRange.min.toLocaleString(),
        max: list.selectedRange.max.toLocaleString(),
      };
      list.initialValue = data;
      list.selectedRange = selectedRangeData;
    }
    if (list.selectedValue && list.selectedValue.includes("All")) {
      list.selectedValue = list.values;
    }
  };

  setDropDownValue = (
    name,
    value,
    tabTitle,
    rangeValue,
    groupTitle,
    dataToRender,
    isDashboardPage,
    companyData
  ) => {
    let renderedTabDetails = JSON.parse(JSON.stringify(dataToRender));
    if (renderedTabDetails && renderedTabDetails.tabsList) {
      let tabsList = renderedTabDetails.tabsList;
      tabsList.forEach((tab) => {
        if (tab && tab.title === tabTitle && tab.dropDownList) {
          this.setDropDownData(
            tab.dropDownList,
            name,
            value,
            rangeValue,
            isDashboardPage,
            companyData
          );
        } else if (groupTitle && tab.groupList) {
          tab.groupList.forEach((group) => {
            if (group.groupTitle === groupTitle) {
              this.setDropDownData(
                group.dropDownList,
                name,
                value,
                rangeValue,
                isDashboardPage,
                companyData
              );
            }
          });
        }
      });
    }
    return { dataToRender: renderedTabDetails };
  };

  setDropDownData = (
    dropDownList,
    name,
    value,
    rangeValue,
    isDashboardPage,
    companyData,
    isCompanyResearchNew
  ) => {
    dropDownList.forEach((list) => {
      if (list.title === name) {
        list.list = [];
        if (companyData) {
          list.selectedValue = value ? value : "";
          list.autoCompleteValue = value ? value : "";
          if (companyData !== "onChange") list.list = companyData;
        } else {
          if (Array.isArray(value)) {
            if (value.includes("All")) {
              if (list.values.length === list.selectedValue.length) {
                value.splice(value.indexOf("All"), 1);
                list.selectedValue = value;
              } else {
                list.selectedValue = list.values;
              }
            } else if (value.length === list.values.length - 1) {
              if (list.selectedValue.includes("All")) {
                list.selectedValue = [];
              } else {
                list.selectedValue = list.values;
              }
            } else {
              list.selectedValue = value;
            }
          } else {
            if (list.selectedValue || list.isTextInput) {
              list.selectedValue = value;
            } else if (rangeValue) {
              if (rangeValue === "nullValue") {
                list.isNullChecked = !list.isNullChecked;
              } else {
                let numbersReg = /^[0-9.,-]+$/,
                  isNegative = false,
                  isNegativeAtFirstChar = true,
                  isSpecialCharactersAtFirstPlace = false;
                isSpecialCharactersAtFirstPlace =
                  value.length === 1 &&
                  (value.includes("-") ||
                    value.includes(".") ||
                    value.includes(","))
                    ? false
                    : true;
                if (
                  value &&
                  value.match(numbersReg) &&
                  isSpecialCharactersAtFirstPlace
                ) {
                  value = value.toString();
                  isNegative = value.includes("-");
                  isNegativeAtFirstChar = value.includes("-")
                    ? value.indexOf("-") === 0
                      ? true
                      : false
                    : true;
                  // eslint-disable-next-line
                  value = value.replace(/\,/g, "");
                  //value = value.indexOf('0') === 0 ? value.slice(1) : value;
                  // eslint-disable-next-line
                  let maxTemp = Number(
                    list.selectedRange["max"].toString().replace(/\,/g, "")
                  );
                  if (isNegativeAtFirstChar) {
                    if (
                      rangeValue === "min" &&
                      (maxTemp >= Number(value) || isNegative)
                    ) {
                      list.selectedRange[rangeValue] = this.numberWithCommas(
                        value
                      );
                      //.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    } else if (rangeValue === "max") {
                      list.selectedRange[rangeValue] = this.numberWithCommas(
                        value
                      );
                      //.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }
                  }
                } else if (
                  value === "" ||
                  (value.length === 1 &&
                    (value.indexOf("-") ||
                      value.indexOf(".") ||
                      value.indexOf(",")))
                ) {
                  list.selectedRange[rangeValue] = "0";
                }
              }
            }
          }
        }
        if (isDashboardPage) {
          if (list.selectedRange) {
            if (rangeValue === "nullValue") {
              list.lastSelectedFilter =
                !list.isNullChecked === list.isNullCheckedInitial;
            } else if (!isEqual(list.selectedRange, list.appliedRange)) {
              list.applyFilteredData = true;
              list.lastSelectedFilter = true;
            } else {
              list.applyFilteredData = false;
              list.lastSelectedFilter = false;
              if (isCompanyResearchNew) {
                if (!list.isFilterApplied) list.lastSelectedFilter = true;
              }
            }
          } else {
            list.lastSelectedFilter = true;
            if (isEqual(list.selectedValue, list.appliedRange)) {
              list.lastSelectedFilter = false;
              if (isCompanyResearchNew) {
                if (!list.isFilterApplied) list.lastSelectedFilter = true;
              }
            }
          }
          //commented to fix issue (Apply show - all is not working)
          if (isCompanyResearchNew) {
            if (!list.isFilterApplied)
              if (
                Array.isArray(list.initialValue) &&
                list.initialValue.includes("All") &&
                list.selectedValue.includes("All")
              ) {
                if (list.isFilterApplied) delete list.lastSelectedFilter;
              } else if (
                isEqual(
                  list.selectedValue ? list.selectedValue : list.selectedRange,
                  list.initialValue
                )
              ) {
                delete list.lastSelectedFilter;
              }
          }
        }
      }
    });
  };

  toggleRangeFilter = (name, tabTitle, groupTitle, dataToRender) => {
    let renderedTabDetails = JSON.parse(JSON.stringify(dataToRender));
    if (renderedTabDetails && renderedTabDetails.tabsList) {
      let tabsList = renderedTabDetails.tabsList;
      tabsList.forEach((tab) => {
        if (tab && tab.title === tabTitle && tab.dropDownList) {
          tab.dropDownList.forEach((list) => {
            if (list.title === name) {
              list.showFilterValues = true;
            }
          });
        } else if (tab && tab.groupList) {
          tab.groupList.forEach((group) => {
            if (group.groupTitle === groupTitle) {
              group.dropDownList.forEach((list) => {
                if (list.title === name) {
                  list.showFilterValues = true;
                }
              });
            }
          });
        }
      });
    }
    return { dataToRender: renderedTabDetails };
  };

  toggleCheck = (event, dataToRender) => {
    let targetValue =
      event.target && event.target.title
        ? event.target.title
        : event.target.name;
    let relatedTarget =
      get(event, "relatedTarget.name") || get(event, "relatedTarget.title");
    if (targetValue !== relatedTarget) {
      let renderedTabDetails = JSON.parse(JSON.stringify(dataToRender));
      if (renderedTabDetails && renderedTabDetails.tabsList) {
        let tabsList = renderedTabDetails.tabsList;
        tabsList.forEach((tab) => {
          if (tab && tab.dropDownList) {
            tab.dropDownList.forEach((list) => {
              if (list.showFilterValues) {
                list.showFilterValues = false;
              }
            });
          } else if (tab && tab.groupList) {
            tab.groupList.forEach((group) => {
              group.dropDownList.forEach((list) => {
                if (list.showFilterValues) {
                  list.showFilterValues = false;
                }
              });
            });
          }
        });
      }
      return { dataToRender: renderedTabDetails };
    }
  };

  numberWithCommas = (value) => {
    var parts = value.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };
}

export default new commonUtil();
